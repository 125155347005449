/** @format */

import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  main: {
    color: '#FFF',
    backgroundColor: 'transparent',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    zIndex: 100,
    position: 'absolute',
    left: 0,
    top: 0
  }
}));

export default useStyles;
