/** @format */

import React, { Suspense, Fragment, lazy } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import AuthGuard from 'src/components/AuthGuard';
import GuestGuard from 'src/components/GuestGuard';
import { DefaultLayout } from 'src/components';
import HomeView from 'src/views/home/HomeView';
import LoadingScreen from 'src/components/LoadingScreen';
import LandingLogin from 'src/views/Landing/landingLogin';
import { NewsDetails } from './views/content/newsDetails';
import { ServicesDetails } from './views/content/services';
import { Profile } from './views/portfolio/profile';
import { PersonalData } from './views/portfolio/personaldata';
import { LegalLayout } from 'src/views/legal-pages/legaLayout';
import { CompanyData } from './views/portfolio/companyData';
import { Access } from './views/portfolio/access';
import Invoice from './views/portfolio/invoice';
import SavedItems from './views/SavedItems/SavedItems';
import Notifications from './views/Notifications';
import WidgetsPreview from './views/widgets';

export const renderRoutes = (routes = []) => (
  <Suspense fallback={<LoadingScreen />}>
    <Switch>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;

        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={props => (
              <Guard>
                <Layout>{route.routes ? renderRoutes(route.routes) : <Component {...props} />}</Layout>
              </Guard>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
);

const routes = [
  {
    exact: true,
    path: '/404',
    component: lazy(() => import('src/views/errors/NotFoundView'))
  },

  {
    exact: true,
    guard: AuthGuard,
    path: '/page/:pageId',
    component: LegalLayout
  },
  {
    exact: true,
    path: '/page/:type/:pageId',
    component: LegalLayout
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/',
    component: LandingLogin
  },

  {
    path: '/',
    guard: AuthGuard,
    layout: DefaultLayout,
    routes: [
      {
        exact: true,
        path: '/dashboard',
        component: HomeView
      },
      {
        exact: true,
        path: '/news-details',
        component: NewsDetails
      },
      {
        exact: true,
        path: '/service-details',
        component: ServicesDetails
      },
      {
        exact: true,
        path: '/profile',
        component: Profile
      },
      {
        exact: true,
        path: '/personaldata',
        component: PersonalData
      },
      {
        exact: true,
        path: '/companydata',
        component: CompanyData
      },
      {
        exact: true,
        path: '/access',
        component: Access
      },
      {
        exact: true,
        path: '/invoice',
        component: Invoice
      },
      {
        exact: true,
        path: '/saved-items',
        component: SavedItems
      },
      {
        exact: true,
        path: '/notifications',
        component: Notifications
      },
      {
        exact: true,
        path: '/widgets-preview',
        component: WidgetsPreview
      },
      {
        exact: true,
        path: '/',
        component: () => <Redirect to="/dashboard" />
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  },
  {
    path: '*',
    layout: DefaultLayout,
    routes: [
      {
        exact: true,
        path: '/',
        component: () => <Redirect to="/dashboard" />
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  }
];

export default routes;
