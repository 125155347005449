/** @format */

import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { IconButton, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
import Grid from '@material-ui/core/Grid';
import { useTranslation } from 'react-i18next';

export default function LanguageSelectorModal({ open, onClose, onLangChange }) {
  const { t } = useTranslation();
  const [lanugageData, setLanguageData] = useState([
    {
      title: 'English',
      value: 'en',
      market: 'Standard'
    },
    {
      title: 'Français',
      value: 'fr',
      market: 'France'
    },
    {
      title: 'Deutsch',
      value: 'de',
      market: 'Deutschland'
    },
    {
      title: 'Español',
      value: 'es',
      market: 'España'
    },
    {
      title: 'Italiano',
      value: 'it',
      market: 'Italia'
    },
    {
      title: 'Dutch',
      value: 'nl',
      market: 'Netherlands'
    },
    {
      title: 'Polish',
      value: 'pl',
      market: 'Poland'
    },
    {
      title: 'Deutsch',
      value: 'de-AT',
      market: 'Österreich'
    },
    {
      title: 'English',
      value: 'en-GB',
      market: 'United Kingdom'
    },
    {
      title: 'Bulgarian',
      value: 'bg',
      market: 'Bulgaria'
    },
    {
      title: 'Czech',
      value: 'cs',
      market: 'Czech Republic'
    },
    {
      title: 'Slovak',
      value: 'sk',
      market: 'Slovakia'
    },
    {
      title: 'Hungarian',
      value: 'hu',
      market: 'Hungary'
    },
    {
      title: 'Serbian',
      value: 'sr',
      market: 'Serbia'
    },
    {
      title: 'Croatian',
      value: 'hr',
      market: 'Croatia'
    },
    {
      title: 'Bosnian',
      value: 'bs',
      market: 'Bosnia and Herzegovina'
    },
    {
      title: 'Danish',
      value: 'da',
      market: 'Denmark'
    },
    {
      title: 'Estonian',
      value: 'et',
      market: 'Estonia'
    },
    {
      title: 'Finnish',
      value: 'fi',
      market: 'Finland'
    },
    {
      title: 'Greek',
      value: 'el',
      market: 'Greece'
    },
    {
      title: 'Icelandic',
      value: 'is',
      market: 'Iceland'
    },
    {
      title: 'Latvian',
      value: 'lv',
      market: 'Latvia'
    },
    {
      title: 'Lithuanian',
      value: 'lt',
      market: 'Lithuania'
    },
    {
      title: 'Norwegian',
      value: 'no',
      market: 'Norway'
    },
    {
      title: 'Portuguese',
      value: 'pt',
      market: 'Portugal'
    },
    {
      title: 'Romanian',
      value: 'ro',
      market: 'Romania'
    },
    {
      title: 'Russian',
      value: 'ru',
      market: 'Russia'
    },
    {
      title: 'Slovenian',
      value: 'sl',
      market: 'Slovenia'
    },
    {
      title: 'Swedish',
      value: 'sv',
      market: 'Sweden'
    },
    {
      title: 'Turkish',
      value: 'tr',
      market: 'Turkey'
    },
    {
      title: 'Ukrainian',
      value: 'uk',
      market: 'Ukraine'
    }
  ]);

  const [lang, setLang] = useState(window.localStorage.i18nextLng || '');

  const handleClose = () => {
    onClose(false);
  };

  const LanguageButton = ({ data, isSelected, onLangChange }) => {
    return (
      <>
        <div
          onClick={() => {
            onLangChange(data?.value);
          }}
          className={isSelected ? 'language-button language-button-selected' : 'language-button'}>
          <div className="language-description">
            <span className="language-title">{data?.title}</span>
            <span className="language-market">{data?.market}</span>
          </div>
          {isSelected && <CheckIcon />}
        </div>
      </>
    );
  };

  return (
    <React.Fragment>
      <Dialog
        fullWidth
        maxWidth="md"
        open={open}
        PaperProps={{
          style: {
            backgroundColor: '#fff'
          }
        }}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title">
        <DialogActions>
          <IconButton onClick={handleClose}>
            <CloseIcon style={{ color: '#000' }} />
          </IconButton>
        </DialogActions>
        <DialogContent>
          <div className="language-selector-body">
            <Typography className="language-selector-title">{t('languageSelector.title')}</Typography>
            <Typography className="language-selector-sub-title">{t('languageSelector.subTitle')}</Typography>
            <Grid container spacing={2}>
              {lanugageData
                ?.sort((a, b) => a.market.localeCompare(b.market))
                .map(item => (
                  <Grid key={item.value} item sm={6}>
                    <LanguageButton data={item} isSelected={Boolean(item.value === lang)} onLangChange={onLangChange} />
                  </Grid>
                ))}
            </Grid>
          </div>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
