/** @format */

import React from 'react';
import { Box, Fade } from '@material-ui/core';
import useStyles from './styles';

const Loader = props => {
  const styles = useStyles();
  const { loading } = props;

  return (
    <Fade
      in={loading}
      style={{
        transitionDelay: loading ? '800ms' : '0ms'
      }}
      unmountOnExit>
      <Box className={styles.main}>
        {/* <CircularProgress /> */}
      </Box>
    </Fade>
  );
};

export default Loader;
