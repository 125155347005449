/** @format */

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const useStyles = makeStyles(theme => ({
  formControl: {
    width: '100%',
    // margin: theme.spacing(1),
    minWidth: 120,
    borderBottom: '2px solid black',
    '& > label': {
      fontSize: 18,
      color: 'black'
    },
    '& .MuiFormLabel-root.Mui-focused': {
      color: 'black'
    }
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  select: {
    color: '#000',
    textAlign: 'left',
    fontSize: 18,
    '&:after': {
      position: 'absolute',
      top: '50%',
      right: 0,
      left: 'auto',
      bottom: 'auto',
      transform: 'translate(0, -50%)!important',
      width: 22,
      height: 22,
      background: `#fea500 url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="8" height="6" viewBox="0 0 8 6" fill="none"><path d="M7.06 0.530029L4 3.58336L0.94 0.530029L0 1.47003L4 5.47003L8 1.47003L7.06 0.530029Z" fill="black"/></svg>') center center no-repeat`,
      border: 0,
      borderRadius: 100
    }
  }
}));

const SelectComponent = props => {
  const classes = useStyles();
  const [value, setValue] = React.useState('');

  const handleChange = event => {
    setValue(event.target.value);
    if (props.onChange) {
      props.onChange(event);
    }
  };
  return (
    <>
      <FormControl className={classes.formControl}>
        <InputLabel>{props?.label}</InputLabel>
        <Select className={classes.select} value={props.value || value} onChange={handleChange}>
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          {props?.options?.map(item => (
            <MenuItem value={item?.value || item?.name}>{item?.name}</MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
};

export default SelectComponent;
