/** @format */

import React from 'react';
import { useTranslation } from 'react-i18next';

export function NewsWidget({ data }) {
  const { t } = useTranslation();
  return (
    <div className="news-widget" data-width={data.w} data-height={data.h}>
      <div className="news-widget--title">
        <h2>{data.title}</h2>
      </div>
      {data.news && (
        <div className="news-widget--list-wrapper">
          <div className="news-widget--list">
            {data.news.length > 0 ? (
              data.news.map(({ nid, title, date, category, content }) => (
                <div className="news-widget--list-item" key={nid}>
                  <div className="news-widget--list-item-top">
                    <div className="news-widget--list-item-title">{title}</div>
                    <div className="news-widget--list-item-date-category">
                      <div className="news-widget--list-item-date">{date || '00.00.0000'}</div>
                      <div className="news-widget--list-item-category">{category || 'category'}</div>
                    </div>
                  </div>
                  <div className="news-widget--list-item-text">{content}</div>
                </div>
              ))
            ) : (
              <p className="news-widget--list-empty">{t('widget.noNews')}</p>
            )}
          </div>
        </div>
      )}
      <div className="news-widget--link">
        <a href="/events">{t('widget.allNews')}</a>
      </div>
    </div>
  );
}
