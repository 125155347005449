/** @format */

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

export function AcademyWidget({ data }) {
  const { t } = useTranslation();
  const [tab, setTab] = useState(1);
  return (
    <div className="academy-widget" data-width={data.w} data-height={data.h}>
      <div className="academy-widget--title">
        <h2>{data.title}</h2>
      </div>
      <div className="academy-widget--tabs-wrapper">
        <ul className="academy-widget--tabs-tab">
          <li>
            <button className={tab === 1 ? 'active' : ''} onClick={() => setTab(1)}>
              {t('widget.newTrainings')}
            </button>
          </li>
          <li>
            <button className={tab === 2 ? 'active' : ''} onClick={() => setTab(2)}>
              {t('widget.bookings')}
            </button>
          </li>
        </ul>
        <div className="academy-widget--tabs-content">
          {tab === 1 && (
            <div id="new-training" className="academy-widget--list">
              {data.trainings.length ? (
                data.trainings.map(training => (
                  <div key={training.id} className="academy-widget--item">
                    <div className="academy-widget--item-image"></div>
                    <div className="academy-widget--item-info">
                      <div className="academy-widget--item-title">{training.title}</div>
                      <div className="academy-widget--item-date-location">
                        <div className="academy-widget--item-date">{training.date}</div>
                        <div className="academy-widget--item-location">{training.location}</div>
                      </div>
                      <div className="academy-widget--item-text">{training.text}</div>
                    </div>
                  </div>
                ))
              ) : data.trainings ? (
                <div className="academy-widget--list-empty">{t('widget.trainingNoTrainingAvailable')}</div>
              ) : (
                <div className="academy-widget--list-empty">{t('widget.trainingDataCouldNotBeLoaded')}</div>
              )}
            </div>
          )}
          {tab === 2 && (
            <div id="booking">
              {data.bookings.length ? (
                data.bookings.map(booking => <div key={booking.id}></div>)
              ) : data.bookings ? (
                <div className="academy-widget--list-empty">{t('widget.trainingNoTrainingAvailable')}</div>
              ) : (
                <div className="academy-widget--list-empty">{t('widget.trainingDataCouldNotBeLoaded')}</div>
              )}
            </div>
          )}
        </div>
      </div>
      <div className="academy-widget--link">
        <a href="#">{t('widget.goToTrainingCenter')}</a>
      </div>
    </div>
  );
}
