/** @format */

import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Button, TextField, Box } from '@material-ui/core';
import { CheckCircleOutline } from '@material-ui/icons';
// import {ArrowRight} from '@material-ui/icons';
import useAuth from 'src/hooks/useAuth';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Breadcrumb from 'src/components/Breadcrumb';
import { useDispatch } from 'react-redux';
import { VALIDATE_REGEX } from 'src/constants';
import { changePassword } from 'src/slices/users';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  root: {
    fontSize: '1.6rem',
    maxWidth: '880px',
    margin: '0 auto',
    marginTop: '136px'
  },
  textColor: {
    color: '#999',
    lineHeight: '2.5rem',
    paddingTop: '1rem'
  },
  btnwrapper: {
    marginTop: 15
  },
  instruction: {
    color: '#161616',
    verticalAlign: 'middle',
    marginBottom: '1rem',
    font: '300 18px/28px ContinentalStagSansW-L',
    fontFamily: 'ContinentalStagSansW-L, "Noto Sans"'
  },
  instructionValid: {
    color: 'rgb(37,189,4)'
  },
  icon: {
    color: '#161616',
    verticalAlign: 'middle',
    fontSize: '2.5rem'
  },
  iconValid: {
    color: 'rgb(37,189,4)'
  },
  btnStyle: {
    borderRadius: '50px',
    font: '500 20px/38px ContinentalStagSansW-M',
    fontFamily: 'ContinentalStagSansW-M, "Noto Sans"',
    color: '#000000',
    background: '#FEA500',
    border: 'none',
    padding: '11px 32px',
    '&:hover': {
      background: '#FEA500',
      border: 'none'
    }
  },
  btnStyleDisabled: {
    borderRadius: '50px',
    font: '500 20px/38px ContinentalStagSansW-M',
    fontFamily: 'ContinentalStagSansW-M, "Noto Sans"',
    color: '#000000',
    background: '#FEA500',
    border: 'none',
    padding: '11px 32px',
    '&:hover': {
      background: '#FEA500',
      border: 'none'
    },
    opacity: '0.5',
    pointerEvents: 'none',
    cursor: 'not-allowed'
  },
  delbtnStyle: {
    marginLeft: '1.5rem',
    borderRadius: 25,
    fontSize: '1.2rem',
    color: '#999',
    fontWeight: '700',
    '&:hover': {
      background: 'transparent'
    }
  },
  textField: {
    // marginLeft: theme.spacing(1),
    // marginRight: theme.spacing(1),
    '& > *': {
      font: '400 18px/18px ContinentalStagSansW',
      fontFamily: 'ContinentalStagSansW, "Noto Sans"',
      color: '#000000'
    },
    '& > .MuiInput-underline': {
      '&:before': {
        borderBottom: '1px solid #FEA500'
      },
      '&.Mui-focused': {
        '&:hover:not(.Mui-disabled):before': {
          borderBottom: '1px solid #FEA500'
        },
        '&:after': {
          borderBottom: '1px solid #FEA500'
        }
      },
      '&:after': {
        borderBottom: '1px solid #FEA500'
      }
    },
    '& > .Mui-focused.MuiInputLabel-formControl': {
      color: '#FEA500'
    }
  },
  footerBtn: {
    marginTop: 25,
    display: 'flex',
    justifyContent: 'flex-end',
    columnGap: '52px',
    alignItems: 'center'
  },
  abortBtn: {
    font: '500 16px/26px ContinentalStagSansW-M',
    fontFamily: 'ContinentalStagSansW-M, "Noto Sans"',
    color: '#000000',
    '&:hover': {
      color: '#FEA500'
    }
  }
}));

const Access = props => {
  const { user } = useAuth();
  const dispatch = useDispatch();
  const classes = useStyles();
  let history = useHistory();
  const [userData, setUserData] = useState(null);
  const goBack = () => {
    history.push('/profile');
  };
  const { t } = useTranslation();
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errorMsg, setErrorMsg] = useState(undefined);
  const [successMsg, setSuccessMsg] = useState(undefined);
  const [specialCase, setSpecialCase] = useState(false);
  const [upperCase, setUpperCase] = useState(false);
  const [number, setNumber] = useState(false);
  const [length, setLength] = useState(false);

  const isValidPassword = [specialCase, upperCase, number, length].every(i => i) && password === confirmPassword;

  useEffect(() => {
    if (user) {
      setUserData(user);
    }
  }, [user]);

  const savePassword = () => {
    let payload = {
      uuid: user.uuid,
      password: password
    };
    if (isValidPassword) {
      dispatch(changePassword(payload)).then(response => {
        if (response) {
          setSuccessMsg(t('Access.successMsg'));
          setTimeout(() => {
            reset();
          }, 3000);
        }
      });
    }
  };

  const reset = () => {
    setConfirmPassword('');
    setPassword('');
    setSuccessMsg(undefined);
    setSpecialCase(false);
    setUpperCase(false);
    setNumber(false);
    setLength(false);
  };

  const passwordValidate = e => {
    const { value } = e.target;
    setPassword(value);
    validateHighlight(value);
    if (confirmPassword && confirmPassword === value) {
      let validation = regexValidate(value);
      if (validation) {
        setErrorMsg(undefined);
      } else {
        setErrorMsg(t('Access.reqPasswordNotFulfil'));
      }
    } else if (confirmPassword) {
      setErrorMsg(t('Access.passwordNotMatch'));
    } else if (!confirmPassword) {
      setErrorMsg(undefined);
    }
  };

  const confirmPasswordValidate = e => {
    const { value } = e.target;
    setConfirmPassword(value);
    if (password === value) {
      let validation = regexValidate(value);
      if (validation) {
        setErrorMsg(undefined);
      } else {
        setErrorMsg(t('Access.reqPasswordNotFulfil'));
      }
    } else {
      setErrorMsg(t('Access.passwordNotMatch'));
    }
  };

  const regexValidate = value => {
    return VALIDATE_REGEX.password.test(value);
  };

  const validateHighlight = value => {
    let specialCaseLetters = /[!,;`~'":{}<>^/@#$%&*?|\\[\])(+=._-]/;

    if (value.match(specialCaseLetters)) {
      setSpecialCase(true);
    } else {
      setSpecialCase(false);
    }

    // Validate capital letters
    let upperCaseLetters = /[A-Z]/g;
    if (value.match(upperCaseLetters)) {
      setUpperCase(true);
    } else {
      setUpperCase(false);
    }

    // Validate numbers
    let numbers = /[0-9]/g;
    if (value.match(numbers)) {
      setNumber(true);
    } else {
      setNumber(false);
    }

    // Validate length
    if (value.length >= 8) {
      setLength(true);
    } else {
      setLength(false);
    }
  };

  const breadcrumbData = [
    {
      title: t('profile.title'),
      link: '/profile',
      options: [
        {
          title: t('profile.title'),
          link: '/profile'
        },
        {
          title: t('personalData.title'),
          link: '/personaldata'
        },
        {
          title: t('Access.title'),
          link: '/access'
        }
      ]
    },
    {
      title: t('Access.title')
    }
  ];

  return (
    <section className="main_content profile">
      <Breadcrumb data={breadcrumbData} />

      <div className="container">
        <h2 className="page_title">{t('Access.title')}</h2>
        <h4 className="page_subtitle">{t('profile.subTitle')}</h4>

        <Box className={classes.root}>
          <Box>
            <h4 className="sub_head">{t('Access.username')}</h4>
            <Box>
              <TextField
                id="standard-full-width"
                label={t('Access.username')}
                placeholder={userData?.email}
                fullWidth
                className={classes.textField}
                margin="normal"
                InputProps={{
                  readOnly: true
                }}
                InputLabelProps={{
                  shrink: true
                }}
              />

              {/* <Grid item xs={12}>
                                <div className={classes.footerBtn}> */}
              {/* <Button className={classes.abortBtn}>{t('personalData.Abortbtn')}</Button> */}
              {''}
              {/* <Button className={classes.btnStyle} variant="outlined" color="primary">{t('personalData.saveBtn')}</Button> */}
              {/* </div>
                            </Grid> */}
            </Box>
          </Box>
          <Box>
            <h4 className="sub_head">{t('Access.changepassword')}</h4>
            <Grid container className={classes.cardHead}>
              <Grid item xs={12}>
                <TextField
                  type="password"
                  id="standard-full-width"
                  label={t('Access.NewPassword')}
                  placeholder={t('Access.NewPassword')}
                  fullWidth
                  className={classes.textField}
                  margin="normal"
                  InputLabelProps={{
                    shrink: true
                  }}
                  value={password}
                  onChange={e => passwordValidate(e)}
                />
              </Grid>
              <Grid item xs={12}>
                <div className={clsx(classes.instruction, length && classes.instructionValid)}>
                  <CheckCircleOutline className={clsx(classes.icon, length && classes.iconValid)} />{' '}
                  {t('Access.instruction_1')}
                </div>
                <div className={clsx(classes.instruction, upperCase && classes.instructionValid)}>
                  <CheckCircleOutline className={clsx(classes.icon, upperCase && classes.iconValid)} />{' '}
                  {t('Access.instruction_2')}
                </div>
                <div className={clsx(classes.instruction, number && classes.instructionValid)}>
                  <CheckCircleOutline className={clsx(classes.icon, number && classes.iconValid)} />{' '}
                  {t('Access.instruction_3')}
                </div>
                <div className={clsx(classes.instruction, specialCase && classes.instructionValid)}>
                  <CheckCircleOutline className={clsx(classes.icon, specialCase && classes.iconValid)} />{' '}
                  {t('Access.instruction_4')}
                </div>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  type="password"
                  id="standard-full-width"
                  label={t('Access.repeatNewPassword')}
                  placeholder={t('Access.repeatNewPassword')}
                  fullWidth
                  className={classes.textField}
                  margin="normal"
                  InputLabelProps={{
                    shrink: true
                  }}
                  value={confirmPassword}
                  onChange={e => confirmPasswordValidate(e)}
                />
              </Grid>

              {errorMsg && (
                <Box sx={{ mt: 2 }}>
                  <span className={classes.errorMsg}>{errorMsg}</span>
                </Box>
              )}
              {successMsg && (
                <Box sx={{ mt: 2 }}>
                  <span className={classes.successMsg}>{successMsg}</span>
                </Box>
              )}

              <Grid item xs={12}>
                <div className={classes.footerBtn}>
                  <Button onClick={goBack} className={classes.abortBtn}>
                    {t('personalData.Abortbtn')}
                  </Button>
                  {''}
                  <Button
                    className={isValidPassword ? classes.btnStyle : classes.btnStyleDisabled}
                    variant="outlined"
                    color="primary"
                    onClick={savePassword}>
                    {t('personalData.saveBtn')}
                  </Button>
                </div>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </div>
    </section>
  );
};

export { Access };
