/** @format */

import { Grid, IconButton, TextField, Typography } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import CloseIcon from '@material-ui/icons/Close';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'src/components/button';
import { updateUserInfo } from 'src/slices/users';
import { useDispatch, useSelector } from 'src/store';
import CancelCreateTicketModal from './cancel';

export default function UpdatePostalCodeModal({ open, onClose, onUpdatePostalCodeSuccess }) {
  const { t } = useTranslation();
  const [cancelModalOpen, setCancelModalOpen] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [postData, setPostData] = React.useState(null);
  const [isValidate, setIsValidate] = React.useState(false);
  const dispatch = useDispatch();
  const { userData, userLoading } = useSelector(state => state.users);

  const handleClose = () => {
    setCancelModalOpen(true);
  };
  const closeNewTicket = () => {
    onClose(false);
    setCancelModalOpen(false);

    // avoid flash of layout
    setTimeout(() => {
      setSuccess(false);
      setError(false);
    }, 300);
  };

  React.useEffect(() => {
    if (userData && !postData) {
      setPostData({ ...userData });
    }
  }, [userData]);

  React.useEffect(() => {
    setIsValidate(false);
    if (postData?.address?.postalCode) setIsValidate(true);
  }, [postData]);

  const updatePostData = (name, value) => {
    if (name === 'postalCode') setPostData({ ...postData, address: { ...postData.address, postalCode: value } });
  };

  const handleSubmit = async () => {
    await dispatch(updateUserInfo(postData));
    onUpdatePostalCodeSuccess();
  };

  return (
    <>
      <Dialog
        fullWidth
        maxWidth="md"
        open={open}
        PaperProps={{
          style: {
            backgroundColor: '#fff',
            borderRadius: 10
          }
        }}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title">
        <DialogActions>
          <IconButton onClick={handleClose} disabled={userLoading}>
            <CloseIcon style={{ color: '#000' }} />
          </IconButton>
        </DialogActions>
        <DialogContent>
          <div className="create-ticket-body">
            <Typography className="create-ticket-title">{t('Tickets.updatePostalCodeTitle')}</Typography>
            <Typography className="create-ticket-subTitle">{t('Tickets.updatePostalCodeSubTitle')}</Typography>
            <Grid container>
              <Grid item xs={12}>
                <Grid style={{ marginBottom: 10, marginTop: 20 }}>
                  <TextField
                    className="create-ticket-text-field"
                    label="Postal Code *"
                    onChange={e => updatePostData('postalCode', e.target.value)}
                    value={postData?.address?.postalCode || ''}
                  />
                </Grid>
              </Grid>
            </Grid>

            <div className="create-ticket-btns">
              <Button
                disabled={!isValidate || userLoading}
                borderOnly
                onClick={handleSubmit}
                additionalClassName="btn-submit">
                {userLoading ? t('personalData.loading') : t('Tickets.submit')}
              </Button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
      <CancelCreateTicketModal
        onClose={() => setCancelModalOpen(false)}
        open={cancelModalOpen}
        closeNewTicket={closeNewTicket}
      />
    </>
  );
}
