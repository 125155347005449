/** @format */
import React from 'react';

// import { useResizeDetector } from 'react-resize-detector';
import { Link } from 'react-router-dom';
import getRedirectionLink from '../../../utils/helper';

const CardLink = ({ link, mainLink }) => {
  return link.link ? (
    <a
      href={getRedirectionLink(link)}
      target={`${link.linkTarget === '_blank' ? '_blank' : ''}`}
      className={mainLink && 'main-link'}>
      <p className="card-text">{link.linkTitle}</p>
    </a>
  ) : (
    <Link to={getRedirectionLink(link)} className={mainLink && 'main-link'}>
      <p className="card-text">{link.linkTitle}</p>
    </Link>
  );
};

const CardMultiLink = props => {
  const data = props.data;
  // const { width, height, ref } = useResizeDetector();

  let image = process.env.REACT_APP_API_URL + data.image;
  if (!data) return <></>;

  return (
    // <div className={`card-multi-link ${useStyles().card}`} ref={ref} data-width={width} data-height={height}>
    <div className={`card-multi-link`}>
      <img src={image} alt="card-icon" />
      <div className="card-flex">
        <div>
          <h2 className={`card-title`}>{data.title}</h2>
          {data.links && <CardLink link={data.links[0]} mainLink />}
        </div>
        {data.links && (
          <div className="others-link">
            <h4>Direct links</h4>
            {data.links.slice(1).map((link, index) => {
              return <CardLink link={link} key={index} />;
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export { CardMultiLink };
