/** @format */

import React, { useState } from 'react';
import ReactPlayer from 'react-player';
import { Badge } from '../../utils';

const VideoContainer = props => {
  // const data = props.data;
  const data =   {
    "type": "video",
    "title": "Video",
    "image": "#",
    "content": props.data.content,
    "link": props.data.link,
    "size": "large"
}
  const [play, setPlay] = useState(false);

  return (
    <div className={`video-container ${play ? `playing` : ``}`}>
      <Badge label={data.title || `Video`} />
      <ReactPlayer
        className="resp-iframe"
        url={props.data.link}
        playing={play}
        width={`100%`}
        height={`100%`}
        onPause={() => setPlay(false)}
        controls={true}
      />
      <i className="play-icon" onClick={() => setPlay(true)}></i>
      <div className="video-title">
        <p>{data.content}</p>
      </div>
    </div>
  );
};

export { VideoContainer };
