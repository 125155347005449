/** @format */

import React from 'react';
import { Grid, TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '../button';
import SelectComponent from '../select';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  textField: {
    width: '100%',
    '& > *': {
      fontSize: 18,
      color: '#333'
    },
    '& > .MuiInput-underline': {
      '&:before': {
        borderBottom: '2px solid black'
      },
      '&:after': {
        borderBottom: '2px solid black'
      }
    },
    '& > .MuiInputLabel-formControl': {
      color: 'black'
    }
  }
}));

export default function SurveyModalStep3({ setStep }) {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <Grid className="survey-step survey-step3">
      <Typography className="survey-step-step">{t('homeSurvey.step3Step')}</Typography>
      <Typography className="survey-step-title">{t('homeSurvey.step3Title')}</Typography>
      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <TextField label={t('homeSurvey.step3Field1')} value="Firma X" className={classes.textField} />
        </Grid>
        <Grid item xs={12} md={6}>
          <SelectComponent
            label={t('homeSurvey.step3Select1')}
            options={[{ value: 'Germany', name: 'Germany' }]}
            value="Germany"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField label={t('homeSurvey.step3Field2')} value="Landsberger Str. 70" className={classes.textField} />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField label={t('homeSurvey.step3Field3')} value="80339" className={classes.textField} />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField label={t('homeSurvey.step3Field4')} value="München" className={classes.textField} />
        </Grid>
        <Grid item xs={12}>
          <TextField label={t('homeSurvey.step3Field5')} value="PF-198510102" className={classes.textField} />
        </Grid>
        <Grid item xs={12}>
          <TextField label={t('homeSurvey.step3Field6')} value="+49 1345 5789000" className={classes.textField} />
        </Grid>
      </Grid>
      <Button
        onClick={() => setStep(4)}
        label={<label>{t('homeSurvey.step3Btn')}</label>}
        className="survey-step-btn step-3"
      />
    </Grid>
  );
}
