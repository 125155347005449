/** @format */

import React, { useState } from 'react';
import { Menu } from 'antd';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import LanguageIcon from '@material-ui/icons/Language';
import { useTranslation } from 'react-i18next';
import LanguageSelectorModal from '../LanugageSelectorModal';
import CheckIcon from '@material-ui/icons/Check';
import NotificationsIcon from '@material-ui/icons/Notifications';
import styles from './globalHeader.module.scss';
import logoutIcon from './icons/logout.svg';
import languageIcon from './icons/language.svg';
import myProfileIcon from './icons/myProfile.svg';
import { Link } from 'react-router-dom';

export const UserDropdown = props => {
  const { t } = useTranslation();

  const [lanugageSelectorToggle, setLanguageSelectorToggle] = useState(false);

  const languageSelectorHandler = event => {
    event.preventDefault();
    setLanguageSelectorToggle(true);
  };

  return (
    <>
      <Menu className={`${styles.menuDropDown} ${styles[`menuDropDown--user`]}`}>
        <Menu.Item key="0">
          {(() => (
            <>
              <Link to="/profile">
                <img src={myProfileIcon} alt="" /> {t('menu.profile')}
              </Link>
              <span className={styles.separate}></span>
            </>
          ))()}
        </Menu.Item>

        <Menu.Item key="2">
          {(() => (
            <>
              <Link to="#" onClick={languageSelectorHandler}>
                <img src={languageIcon} alt="" /> {t('menu.language')}
              </Link>
              <span className={styles.separate}></span>
            </>
          ))()}
        </Menu.Item>
        {/* <Menu.Item key="4">
          <a href="/saved-items">
            <CheckIcon /> &nbsp; {t('menu.savedItems')}
          </a>
        </Menu.Item>
        <Menu.Item key="5">
          <a href="/notifications">
            <NotificationsIcon /> &nbsp; {t('menu.notifications')}
          </a>
        </Menu.Item> */}
        {props.user.userclass === 'Vision' && ( // has { mvp, vision } show only Vision
          <Menu.Item key="1">{/* <Link to="/">{t('menu.settings')}</Link> */}</Menu.Item>
        )}

        <Menu.Item key="3">
          {(() => (
            <a
              href="/"
              onClick={e => {
                e.preventDefault();
                props.logout();
              }}>
              <img src={logoutIcon} alt="" /> {t('menu.logout')}
            </a>
          ))()}
        </Menu.Item>
      </Menu>
      <LanguageSelectorModal
        open={lanugageSelectorToggle}
        onClose={() => {
          setLanguageSelectorToggle(false);
        }}
        onLangChange={data => {
          props.onLangChange(data);
        }}
      />
    </>
  );
};
