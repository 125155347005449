/** @format */

import React, { useEffect } from 'react';
// import { getBuildVersion } from 'src/utils/version';

import { useDispatch } from 'src/store';
import { getFooterLinksInfo } from 'src/slices/global';
import useAuth from 'src/hooks/useAuth';
import { keyclockConfig } from 'src/config';

const LandingLogin = () => {
  const dispatch = useDispatch();
  const { isAuthenticated, onLogin } = useAuth();

  useEffect(() => {
    const Storage = window.localStorage;
    let lang = Storage.i18nextLng;
    dispatch(getFooterLinksInfo(lang));
    // eslint-disable-next-line
  }, []);

  if (!isAuthenticated) {
    const currentUrl = window.location.origin;
    window.location.href =
      keyclockConfig.url +
      '/realms/master/protocol/openid-connect/auth?client_id=' +
      keyclockConfig.clientId +
      '&redirect_uri=' +
      currentUrl +
      '/dashboard&state=3200ecb1-a932-425d-a06c-a6294c68dbff&response_mode=fragment&response_type=code&scope=openid&nonce=74f4ae04-9874-49b0-b473-19087f5dbfa3';
  }

  return <></>;
};

export default LandingLogin;
