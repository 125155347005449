/** @format */

import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import useStyles from 'src/components/Loader/styles';
import { numberFormat } from 'src/components/utils';

const MobilityWidget = ({ data }) => {
  const { t } = useTranslation();
  const { title, state, money } = data;
  return (
    <div className={`mobility-widget ${useStyles().card}`} data-width={data.w} data-height={data.h}>
      <div className="mobility-widget--title">
        <h2>{data.title} </h2>
      </div>
      {money ? (
        <>
          <div className="mobility-widget--data">
            <p>{numberFormat(money.reach)}</p>
            <span style={{ width: `${(money.reach * 100) / money.target}%` }} />
          </div>
          <p className="mobility-widget--text">{t('widget.mobilityText')}</p>

          <div className="mobility-widget--link">
            <a href="/events">{t('widget.mobilityRedeemPoints')}</a>
          </div>
        </>
      ) : state === 'onboarding' ? (
        <>
          <p className="mobility-widget--state-text">{t('widget.mobilityEnterVAT')}</p>
          <div className="mobility-widget--link">
            <a href="/events">{t('widget.mobilityEnter')}</a>
          </div>
        </>
      ) : (
        <>
          <p className="mobility-widget--state-text">{t('widget.mobilityNoData')}</p>
          <div className="mobility-widget--link">
            <a href="/events">{t('widget.mobilityRedeemPoints')}</a>
          </div>
        </>
      )}
    </div>
  );
};

export { MobilityWidget };
