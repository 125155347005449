/** @format */

// import { Content } from 'antd/lib/layout/layout';
import React, { useEffect, useState } from 'react';
import { SilverBackground } from './silverBackground';
import { ContentBlock } from './contentBlock';
// import { Backbtn } from 'src/components/button/backBtn';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'src/store';
import { Header } from 'src/components/header/header';
import { Footer } from 'src/components/footer/footer';
import { getPublicContentInfo, getPrivateContentInfo } from 'src/slices/global';
import { getFooterLinksInfo } from 'src/slices/global';
import { Redirect } from 'react-router';
import i18n from '../../i18n';
import useAuth from 'src/hooks/useAuth';

import CookiesLayer from 'src/cookieLayer';
import Breadcrumb from 'src/components/Breadcrumb';
import GlobalHeader from 'src/components/globalHeader';
import { Box } from '@material-ui/core';

const LegalLayout = props => {
  const { user } = useAuth();
  const { pageId, type } = useParams();
  const dispatch = useDispatch();
  const [contentInfo, setContentInfo] = useState({});
  const [userLang, setLang] = useState();
  const { footerLinks, error } = useSelector(state => state.global);
  // console.log(error)

  useEffect(() => {
    const Storage = window.localStorage;
    let lang = Storage.i18nextLng;
    if (pageId) {
      if (type === 'public') {
        if (user) {
          dispatch(
            getPublicContentInfo(pageId, lang, res => {
              setContentInfo(res);
              // console.log(res);
            })
          );
          setLang(lang);
        } else {
          dispatch(
            getPublicContentInfo(pageId, lang, res => {
              setContentInfo(res);
              // console.log(res);
            })
          );
          dispatch(getFooterLinksInfo(lang));
        }
      } else {
        dispatch(
          getPrivateContentInfo(
            pageId,
            lang,
            res => {
              setContentInfo(res);
            },
            () => {}
          )
        );
      }
    }
    // eslint-disable-next-line
  }, [pageId]);

  useEffect(() => {
    const Storage = window.localStorage;
    let lang = Storage.i18nextLng;
    if (user) {
      setLang(lang);
      i18n.changeLanguage(lang);
    } else {
      setLang(lang);
    }
  }, [props, user]);

  const getSelectLang = e => {
    const { value } = e.target;
    setLang(value);

    if (type === 'public') {
      if (user) {
        dispatch(
          getPublicContentInfo(pageId, value, res => {
            setContentInfo(res);
            // console.log(res);
          })
        );
      } else {
        dispatch(
          getPublicContentInfo(pageId, value, res => {
            setContentInfo(res);
            // console.log(res);
          })
        );
      }
    } else {
      dispatch(
        getPrivateContentInfo(
          pageId,
          value,
          res => {
            setContentInfo(res);
          },
          () => {}
        )
      );
    }

    i18n.changeLanguage(value);
  };

  return (
    <>
      <section className="main_section">
        {error && <Redirect to="/404" />}
        <GlobalHeader />
        <Header getSelectLang={getSelectLang} userLang={userLang} />
        <Box sx={{ mt: '228px' }}>
          <Breadcrumb data={[{ title: contentInfo?.title }]} />
          <SilverBackground title={contentInfo?.title || ''} />
          <ContentBlock content={contentInfo} />
        </Box>
        <Footer />
      </section>
    </>
  );
};

export { LegalLayout };
