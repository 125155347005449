/** @format */
import React from 'react';
const SilverBackground = props => {
  return (
    <div className="silver-background container">
      <h1>{props.title}</h1>
    </div>
  );
};

export { SilverBackground };
