/** @format */

import React from 'react';
import { Link } from 'react-router-dom';
import { Badge } from '../../utils';
import getRedirectionLink from '../../../utils/helper';

const News = props => {
  const data = props.data;
  return (
    <div className="news-grid">
      <div className="news-container">
        <Badge label={data.title} />
        {/* <div className="video-badge">NEWS</div> */}
        {data && data.news && data.news.map((item, index) => {
          return (
            <div key={index} className={`news-box new-box-${index}`}>
              {item?.link ? (
                <a href={getRedirectionLink(item)} target={`${item.linkTarget === '_blank' ? '_blank' : ''}`} className="news-link-wrap">
                  <h1 className={`${index === 0 ? `news-h1` : `subnews-h1`}`}>{item.title}</h1>
                  <p className="news-p1">{item.content}</p>
                </a>
              ) : (
                <Link to={getRedirectionLink(item)}>
                  <h1 className={`${index === 0 ? `news-h1` : `subnews-h1`}`}>{item.title}</h1>
                  <p className="news-p1">{item.content}</p>
                </Link>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export { News };
