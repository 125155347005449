/** @format */

import React from 'react';
import contact from '../../assets/imgs/card-icons/Phone.png';

const CallBtn = props => {
  return (
    <a href={`tel:${props.number}`}>
      <div>
          <button className="call-btn-blue"> 
            <img src={ contact } alt=""/>
            {props.number}
          </button>
      </div>
    </a>
  );
};

export { CallBtn };
