/** @format */

import * as React from 'react';
import { IconButton, Typography } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import CloseIcon from '@material-ui/icons/Close';
import { useTranslation } from 'react-i18next';
import { Button } from 'src/components/button';
import { useDispatch, useSelector } from 'src/store';
import { updateTicket } from 'src/slices/tickets';

const initPostData = {
  incidentid: null,
  commentmessage: null,
  attachment: null,
  attachmentFileName: null
};

export default function CloseTicketModal({ open, onClose, incidentid, ticketnumber }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { tickets, ticketLoading, error } = useSelector(state => state.tickets);
  const [currentTicket, setCurrentTicket] = React.useState(null);
  const [postData, setPostData] = React.useState(initPostData);
  React.useEffect(() => {
    if (tickets && incidentid && !ticketnumber) {
      setCurrentTicket(tickets.find(t => t.incidentid === incidentid));
    }
    if (ticketnumber) {
      setCurrentTicket({
        ticketnumber
      });
    }
    setPostData({
      ...postData,
      commentmessage: 'I want to cancel this ticket',
      incidentid
    });
  }, [tickets, incidentid]);

  const handleClose = () => {
    onClose(false);
  };
  const handleCancelTicket = async () => {
    await dispatch(updateTicket(postData));
    setPostData(initPostData);
    onClose(false);
  };

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={open}
      PaperProps={{
        style: {
          backgroundColor: '#fff'
        }
      }}
      onClose={handleClose}
      aria-labelledby="max-width-dialog-title">
      <DialogActions>
        <IconButton onClick={handleClose}>
          <CloseIcon style={{ color: '#000' }} />
        </IconButton>
      </DialogActions>
      <DialogContent>
        <div className="create-ticket-body create-ticket-body--cancel">
          <Typography className="create-ticket-title create-ticket-title--cancel">
            {t('Tickets.cancelTitle')}
          </Typography>
          <div className="your-case-number">
            {t('Tickets.yourCaseNumber')}
            <br />
            {currentTicket?.ticketnumber}
          </div>
          <div className="cancel-create-ticket-btns">
            <Button ghost noArrow onClick={handleClose} additionalClassName="btn-no">
              {t('Tickets.no')}
            </Button>
            <Button disabled={ticketLoading} borderOnly onClick={handleCancelTicket} additionalClassName="btn-yes">
              {ticketLoading ? t('personalData.loading') : t('Tickets.yes')}
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}
