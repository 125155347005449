/** @format */

import React from 'react';
import styles from './globalHeader.module.scss';

export const Avatar = props => {
  return (
    <>
      <div className={`${styles.avatarBox} avatarBox`}>
        {props.srcImg !== '' ? (
          <img src={props.srcImg} alt="Avatar" />
        ) : (
          <span className={styles.avatarBox__firstLetter}>{props.name.charAt(0)}</span>
        )}
      </div>
    </>
  );
};
