/** @format */

import React, { Fragment, useState } from 'react';
// import HomeIcon from 'src/assets/icon/homeBlue.png';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import { HomeIcon } from './icons';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  menuItemText: {
    color: 'rgba(35, 77, 150, 1)',
    marginRight: '8px',
    fontRamily: 'Noto Sans',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '24px',
    textDecoration: 'underlined'
  },
  menu: {
    '& .MuiPaper-root': {
      backgroundColor: '#fff'
    }
  }
}));

const Breadcrumb = ({ data }) => {
  // const data = [
  //     {
  //         title: "Parent page",
  //         link: "/"
  //     },
  //     {
  //         title: "Parent page with parallels",
  //         link: '/',
  //         options: [
  //             {
  //                 title: "Parallel page same level",
  //                 link: "/page/28"
  //             },
  //             {
  //                 title: "Parallel page same level",
  //                 link: "/page/33"
  //             }
  //         ]
  //     },
  //     {
  //         title: "Current page"
  //     }
  // ]

  const BreadcrumbItem = ({ title, link, currentPage }) => (
    <>
      <span className="breadcrumbItemSeparator">/</span>
      <span className={currentPage ? 'breadcrumbItemSeparator' : 'breadcrumbItemText'}>
        <a href={link}>{title}</a>
      </span>
    </>
  );

  const BreadcrumbItemDropdown = ({ title, options, currentPage, key }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const classes = useStyles();

    const handleClick = event => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };
    return (
      <>
        <span className="breadcrumbItemSeparator">/</span>
        <div
          aria-controls={`breadcrumb_dropdown_${key}`}
          className="breadcrumbItemDropdownBtn"
          aria-haspopup="true"
          onClick={handleClick}>
          <span
            className={
              currentPage
                ? 'breadcrumbItemSeparator breadcrumbItemDropdownBtn'
                : 'breadcrumbItemText breadcrumbItemDropdownBtn'
            }>
            {title} &nbsp; <ArrowDropDownIcon />
          </span>
        </div>
        <Menu
          className={classes.menu}
          id={`breadcrumb_dropdown_${key}`}
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}>
          {options?.map((item, index) => {
            return (
              <MenuItem key={index} className={classes.menuItemText} onClick={handleClose}>
                <a href={item?.link}>{item?.title}</a>
              </MenuItem>
            );
          })}
        </Menu>
      </>
    );
  };

  return (
    <>
      <div className="breadcrumbWrapper container">
        <Link to="/">
          {/* <img src={HomeIcon} alt="_home.png" className='homeIcon' /> */}
          <HomeIcon />
        </Link>

        {data?.map((item, index) => {
          if (item?.options && item?.options?.length) {
            return (
              <Fragment key={index}>
                <BreadcrumbItemDropdown
                  title={item?.title}
                  options={item?.options}
                  currentPage={Boolean(data?.length - 1 === index)}
                />
              </Fragment>
            );
          }

          return (
            <Fragment key={index}>
              <BreadcrumbItem
                key={index}
                title={item?.title}
                link={item?.link}
                currentPage={Boolean(data?.length - 1 === index)}
              />
            </Fragment>
          );
        })}
      </div>
    </>
  );
};

export default Breadcrumb;
