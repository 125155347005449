/** @format */

import React from 'react';
import { useTranslation } from 'react-i18next';

export function MagazineWidget({ data }) {
  const { t } = useTranslation();
  return (
    <div className="magazine-widget" data-width={data.w} data-height={data.h}>
      <div className="magazine-widget--title">
        <h2>{data.title} </h2>
      </div>
      <div className="magazine-widget--list-wrapper">
        <div className="magazine-widget--list">
          {data.data?.length > 0 ? (
            data.data.map(({ image, thumbnail, title, date, category, text }, i) => (
              <div className="magazine-widget--list-item" key={i}>
                <div className="magazine-widget--list-item-image">
                  <img
                    src={
                      image
                        ? process.env.REACT_APP_API_URL + image
                        : thumbnail
                        ? process.env.REACT_APP_API_URL + thumbnail
                        : 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7'
                    }
                    alt=""
                  />
                </div>
                <div className="magazine-widget--list-item-title">{title}</div>
                <div className="magazine-widget--list-item-info">
                  <div className="magazine-widget--list-item-date">{date}</div>
                  <div className="magazine-widget--list-item-category">{category}</div>
                </div>
                <div className="magazine-widget--list-item-text">{text}</div>
              </div>
            ))
          ) : (
            <p className="magazine-widget--list-empty">{t('widget.noMagazinArticles')}</p>
          )}
        </div>
      </div>
      <div className="magazine-widget--link">
        <a href="/magazine">{t('widget.allMagazinArticles')}</a>
      </div>
    </div>
  );
}
