/** @format */

import * as React from 'react';
import { IconButton, Typography } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import CloseIcon from '@material-ui/icons/Close';
import { useTranslation } from 'react-i18next';
import { Button } from 'src/components/button';

export default function CancelCreateTicketModal({ open, onClose, closeNewTicket }) {
  const { t } = useTranslation();
  const handleClose = () => {
    onClose(false);
  };
  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={open}
      PaperProps={{
        style: {
          backgroundColor: '#fff'
        }
      }}
      onClose={handleClose}
      aria-labelledby="max-width-dialog-title">
      <DialogActions>
        <IconButton onClick={handleClose}>
          <CloseIcon style={{ color: '#000' }} />
        </IconButton>
      </DialogActions>
      <DialogContent>
        <div className="create-ticket-body create-ticket-body--cancel">
          <Typography className="create-ticket-title create-ticket-title--cancel">
            {t('Tickets.cancelTitle')}
          </Typography>
          {/* <div className="your-case-number">
            {t('Tickets.yourCaseNumber')}
            <br />
            CAS-06477-C3P3R2
          </div> */}
          <div className="cancel-create-ticket-btns">
            <Button ghost noArrow onClick={handleClose} additionalClassName="btn-no">
              {t('Tickets.no')}
            </Button>
            <Button borderOnly onClick={closeNewTicket} additionalClassName="btn-yes">
              {t('Tickets.yes')}
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}
