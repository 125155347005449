/** @format */

import React from 'react';
import { useTranslation } from 'react-i18next';

export function ExtranetWidget({ data }) {
  const { t } = useTranslation();
  const isProduction = process.env.REACT_APP_API_URL === 'https://backend.my.vdo.com/';
  return (
    <div className="extranet-widget" data-width={data.w} data-height={data.h}>
      <div className="extranet-widget--title">
        <h2>{data.title}</h2>
      </div>
      {data.data ? (
        <>
          <div className="extranet-widget--search">
            <input placeholder="Suchwort eingeben" />
          </div>
          <div className="extranet-widget--list-wrapper">
            <h3>{t('widget.newContentExtranetForYou')}</h3>
            <div className="extranet-widget--list">
              {data.data.length > 0 ? (
                data.data.map(d => (
                  <div className="extranet-widget--list-item" key={d.id}>
                    <div className="extranet-widget--list-item-image"></div>
                    <div className="extranet-widget--list-item-title">{d.title}</div>
                    <div className="extranet-widget--list-item-date-category">
                      <div className="extranet-widget--list-item-date">{d.date}</div>
                      <div className="extranet-widget--list-item-category">{d.category}</div>
                    </div>
                  </div>
                ))
              ) : (
                <p className="extranet-widget--list-empty">{t('widget.noExtranetContent')}</p>
              )}
            </div>
          </div>
        </>
      ) : (
        <div className="extranet-widget--information">
          <p>{t('widget.applyExtranet')}</p>
          <img src="/static/images/extranet.png" />
        </div>
      )}
      <div className="extranet-widget--link">
        <a
          href={
            isProduction
              ? 'https://extranet.continental-partner.com/'
              : 'https://staging.extranet.continental-partner.com/'
          }>
          {t('widget.goToExtranetPortal')}
        </a>
      </div>
    </div>
  );
}
