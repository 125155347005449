import React, {useEffect} from 'react';


const Invoice = (props) => {
    useEffect(() => {
        addTokenToWidgetSdk();
        addScriptsHandler();
        addTagHandler();
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        addScriptsHandler();
        // eslint-disable-next-line
    }, [props.lang])

    const addTokenToWidgetSdk = () => { //this handler is just to set token for widgets (made by DMG) to access it
        window["customerPortal"] = {
            token: localStorage.getItem('accessToken')
        }
    }
    // console.log(props.data)
    const addScriptsHandler = () => {
        const parentElement = document.getElementById("script-"+props.data.tag);
        // const token = `token=${localStorage.getItem('accessToken')}`
        if(parentElement) {
            // console.log("REMOVEVEEVEEVVEVE")
            parentElement.remove();
        }
        const srcRoute = process.env.REACT_APP_API_URL + props.data.jsSource;
        var s = document.createElement('script');
        s.setAttribute("id", "script-"+props.data.tag)
        s.setAttribute('src', srcRoute);        
        s.setAttribute('type', "text/javascript");
        document.head.appendChild(s);
    }

    const addTagHandler = () => {
        const parent = document.getElementById(props.data.tag);
        let tag = document.createElement(props.data.tag);
        const currentLanguage = window.localStorage.i18nextLng;
        tag.setAttribute("lang", currentLanguage);
        parent.appendChild(tag);
    }

    return (
        <>
            <div id={props.data.tag} className={props.profileCard?`dynamic-widget-card_profile`:`dynamic-widget-card`}>
                
            </div>
        </>
    )
}

export default Invoice;