/** @format */

import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Card, CardContent, Box } from '@material-ui/core';
import { Person, ExitToApp, ArrowRight } from '@material-ui/icons';
import { Avatar } from 'src/components/globalHeader/avatar';
import { Link } from 'react-router-dom';
import useAuth from 'src/hooks/useAuth';
import { getWidgetInfo } from 'src/slices/users';
import { useDispatch, useSelector } from 'src/store';
import Breadcrumb from 'src/components/Breadcrumb';
import { css, cx } from '@emotion/css';
import { ChevronRight, ExitIcon, PersonIcon } from 'src/components/icons';
// import { useState } from 'react';
// import Invoice from './invoice';

const customCSS = {
  cardsContainer: css`
    display: flex;
    column-gap: 32px;
    margin-top: 136px;
    margin-bottom: 200px;
  `,
  cardsWrap: css`
    flex: 0 0 525px;
    .card {
      background: #ffffff;
      font-size: 16px;
      color: #333;
      min-height: 326px;
      position: relative;
      box-shadow: none;
      display: flex;
      flex-direction: column;
      padding: 28px 28px 56px;
    }
  `,
  cardHead: css`
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    column-gap: 12px;
    span {
      font: 500 18px/28px ContinentalStagSansW-M;
      font-family: 'ContinentalStagSansW-M', 'Noto Sans';
    }
  `,
  cardLink: css`
    position: absolute;
    bottom: 32px;
    color: #fea500;
    cursor: pointer;
    font: 400 18px/21px ContinentalStagSansW;
    font-family: 'ContinentalStagSansW', 'Noto Sans';
  `
};

const useStyles = makeStyles(theme => ({
  // icon: {
  //     '& > span': {
  //         margin: theme.spacing(2),
  //     },
  // },
}));

const Profile = props => {
  const { user } = useAuth();
  const { userProfile } = useSelector(state => state.users);
  const dispatch = useDispatch();
  // const { widgetInfo } = useSelector(state => state.users);
  // const [invoicesData, setInvoiceData] = useState(null)
  const classes = useStyles();
  // console.log(user)
  // api
  useEffect(() => {
    const Storage = window.localStorage;
    let lang = Storage.i18nextLng;

    if (user) {
      dispatch(getWidgetInfo(lang));
    }
    // eslint-disable-next-line
  }, []);
  // useEffect(()=>{
  //     if(widgetInfo){
  //         renderWidget(widgetInfo.data)
  //     }
  // }, [widgetInfo])

  // const renderWidget =(data)=>{
  //     for(let i=0; i < data.length; i++){
  //         if(data[i].type === 'dynamic' && data[i].tag === 'tis-web-invoices'){
  //             setInvoiceData(data[i])
  //         }
  //     }
  // }
  const { t } = useTranslation();

  const role = useMemo(() => {
    if (user && Object.keys(user).length > 0) {
      const { scenario, role, roleOther, roles } = user;
      if (roleOther) return roleOther;
      if (role) return t(`personalData.roles.${role}`);
      if (scenario) return t(`personalData.scenario.${scenario}`);
      if (roles && roles.length > 0) return roles[0];
      return 'Fleet Manager';
    }
    return '';
  }, [user, t]);

  return (
    <section className="main_content profile">
      <Breadcrumb data={[{ title: t('profile.title') }]} />

      <Box className="container">
        <h2 className="page_title">{t('profile.title')}</h2>
        <h4 className="page_subtitle">{t('profile.subTitle')}</h4>

        <Box className={cx(customCSS.cardsContainer)}>
          <Box className={cx(customCSS.cardsWrap)}>
            <Card className="card">
              <Box className={cx(customCSS.cardHead)}>
                {/* <Person fontSize="large" /> */}
                <PersonIcon />
                <span>{t('profile.Personaldata')}</span>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', columnGap: '32px', marginLeft: '28px' }}>
                <Box>
                  <Avatar srcImg={userProfile?.picture || ''} name={`${user?.firstName} ${user?.lastName}`} />
                </Box>
                <Box>
                  <p>{user && user.firstName && `${user?.firstName} ${user?.lastName}`}</p>
                  <span className="title">{role}</span>
                </Box>
              </Box>
              <Link to="/personaldata" className={cx(customCSS.cardLink)}>
                {/* <ArrowRight className={classes.linkcolor} /> */}
                <ChevronRight />
                <Box component="span" sx={{ ml: '14px' }}>
                  {t('profile.cta')}
                </Box>
              </Link>
            </Card>
          </Box>
          {/* <Grid item xs={12} lg={4}>
                    <Card className={classes.card}>
                        <CardContent>
                        <Grid container className={classes.cardHead}>
                            <Grid item xs={1}>
                                <Business fontSize="large" color='primary' />
                            </Grid>
                            <Grid item xs={11}>
                                <strong>{t('profile.Companydata')}</strong>
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item xs={12}>
                            </Grid>
                        </Grid>
                        <Link to="/companydata" className={classes.cardlink}><ArrowRight className={classes.linkcolor}/> {t('profile.cta')}</Link>
                        </CardContent>
                    </Card>
                    </Grid> */}
          <Box className={cx(customCSS.cardsWrap)}>
            <Card className="card">
              <Box className={cx(customCSS.cardHead)}>
                {/* <ExitToApp fontSize="large" /> */}
                <ExitIcon />
                <span>{t('profile.Access')}</span>
              </Box>
              {/* <Grid container>
                                <Grid item xs={12}>

                                </Grid>
                            </Grid> */}
              <Link to="/access" className={cx(customCSS.cardLink)}>
                {/* <ArrowRight className={classes.linkcolor} /> */}
                <ChevronRight />
                <Box component="span" sx={{ ml: '14px' }}>
                  {t('profile.cta')}
                </Box>
              </Link>
              {/* <div className={classes.cardlink}><ArrowRight className={classes.linkcolor}/>{t('profile.cta')}</div> */}
            </Card>
          </Box>
          {/* <Grid item xs={4}>
                    <Card className={classes.card}>
                        <CardContent>
                        <Grid container className={classes.cardHead}>
                            <Grid item xs={1}>
                                <VerticalSplit fontSize="large" color='primary' />
                            </Grid>
                            <Grid item xs={11}>
                                <strong>{t('profile.Licenses')}</strong>
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item xs={12}>
                                {t('profile.LicensesText')}
                            </Grid>
                        </Grid>
                        <div className={classes.cardlink}><ArrowRight className={classes.linkcolor}/>{t('profile.cta')}</div>
                        </CardContent>
                    </Card>
                    </Grid> */}
          {/* <Grid item xs={12} lg={4}>
                    <Card className={classes.card}>
                        <CardContent>
                        <Grid container className={classes.cardHead}>
                            <Grid item xs={1}>
                                <VerticalSplit fontSize="large" color='primary' />
                            </Grid>
                            <Grid item xs={11}>
                                <strong>{t('profile.bills')}</strong>
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item xs={12}>
                                {invoicesData ? <Invoice data={invoicesData} lang={window.localStorage.i18nextLng} profileCard={true}/>:t('profile.billText')}
                                
                            </Grid>
                        </Grid>
                        </CardContent>
                    </Card>
                    </Grid> */}
        </Box>
      </Box>
    </section>
  );
};

export { Profile };
