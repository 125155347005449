/** @format */

import React, { useCallback, useEffect, useState } from 'react';
import { Header } from '../header';
import { Footer } from '../footer';
import useAuth from 'src/hooks/useAuth';
import { useDispatch, useSelector } from 'src/store';
import i18n from '../../i18n';
import { getWidgetInfo } from 'src/slices/users';
import { getFooterLinksInfo } from 'src/slices/global';
import CookiesLayer from 'src/cookieLayer';
import GlobalHeader from '../globalHeader';

const DefaultLayout = props => {
  const { isAuthenticated, user } = useAuth();
  const [userLang, setLang] = useState();
  const dispatch = useDispatch();
  const { footerLinks } = useSelector(state => state.global);

  useEffect(() => {
    const Storage = window.localStorage;
    let lang = Storage.i18nextLng;
    // debugger
    if (user?.locale) {
      setLang(lang);
      i18n.changeLanguage(lang);
      dispatch(getWidgetInfo(lang));
      dispatch(getFooterLinksInfo(lang));
    } else {
      setLang(lang);
    }
    // eslint-disable-next-line
  }, [props, user]);

  const getSelectLang = useCallback(e => {
    const { value } = e.target;
    setLang(value);
    if (user) {
      dispatch(getWidgetInfo(value));
    }
    dispatch(getFooterLinksInfo(value));
    i18n.changeLanguage(value);
    window.location.reload();
  }, []);

  return (
    <>
      <section className="main_section">
        <GlobalHeader getSelectLang={getSelectLang} userLang={userLang} />
        <Header />

        <div className="content">
          {isAuthenticated && props.children // check if logined show content
          }
        </div>

        <Footer />
      </section>
    </>
  );
};

export { DefaultLayout };
