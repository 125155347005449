import React, {useEffect} from "react";
import {
    GoogleMap,
    useLoadScript,
    Marker,
    InfoWindow
} from '@react-google-maps/api';
import { formatRelative } from 'date-fns';

import { useTranslation } from 'react-i18next';

import usePlacesAutocomplete, { getGeocode, getLatLng, } from "use-places-autocomplete";

import { Combobox, ComboboxInput, ComboboxPopover, ComboboxList, ComboboxOption, } from "@reach/combobox";

import "@reach/combobox/styles.css";
import { getLocation } from "connected-react-router";

const libraries = ["places"]
const mapContainerStyle = {
    width: '100%',
    height: '100%'
}

const optionsStyle = {
    width:'100%',
    fontSize: '1.7rem',
    marginTop:'1rem',
    fontFamily: 'inherit'
}

const popoverStyle = {
    marginTop:'1rem'
}

const center = {
    lat: 50,
    lng: 10
}


const options = {
    disableDefaultUI: true,
    zoomControl: true,
}

export default function Map(props) {
    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: 'AIzaSyAKfmH0DO3BhuwmDr4B3-nCtjpEsswf4FI',
        libraries
    });

    const { t } = useTranslation();
    
    const [marker, setMarker] = React.useState({lat:props.lat,lng:props.long});
    //const [center, setCenter] = React.useState({lat:51,lng:10})

    useEffect(() => {
        setMarker({lat:props.lat,lng:props.long});
    }, [props?.lat, props?.long]);

    

    
    // const onMapClick = React.useCallback((event) => {
        
    //     const lat = event.latLng.lat()
    //     const lng = event.latLng.lng()
    //     setMarker({
    //             lat: lat,
    //             lng: lng
    //         }
    //     );

    // }, [])

    const onMapClick = (event) => {
        const lat = event.latLng.lat()
        const lng = event.latLng.lng()
        setMarker({
                lat: lat,
                lng: lng
            }
        );
        if (props.setLatLong) {
            props.setLatLong(lat,lng)
        }
    }

 

    const mapRef = React.useRef();

    const onMapLoad = React.useCallback((map) => {
        mapRef.current = map;
    },[])


    const panTo = React.useCallback(({lat, lng}) => {
        if(mapRef?.current) {
            mapRef.current.panTo({lat,lng});
            mapRef.current.setZoom(17);
        }
    },[]);


    if (loadError) return t('map.ErrorLoadingMaps');
    if (!isLoaded) return t('map.LoadingMaps');

    return (
        <div className="map-area">
            <Search panTo={panTo} />
            <GoogleMap
                mapContainerStyle={mapContainerStyle}
                zoom={8}
                center={props.lat === null || props.long === null ? center : marker}
                options={options}
                onClick={onMapClick}
                onLoad={(data) => {
                    onMapLoad(data);
                    
                        if(props?.onMapLoad && props.lat == null && props.long ==null) {
                            props.onMapLoad();
                        }
                    
                }}
                >
                    <Marker 
                      position={{ lat: marker.lat, lng: marker.lng }}
                    />
                </GoogleMap>
                
        </div>
    )
}


function Search({panTo}) {
    const { ready, value, suggestions: { status, data }, setValue, clearSuggestions } = usePlacesAutocomplete({
        requestOptions: {
            location: { lat: () => 50, lng: () => 10 },
            radius: 200000,
        },
    })

    const { t } = useTranslation();

    return (
        <div class="map-search-wrapper">
            <Combobox
                className="search"
                onSelect={async (address) => {
                    setValue(address, false);
                    clearSuggestions();
                    try {
                        const results = await getGeocode({address})
                        const { lat, lng } = await getLatLng(results[0])
                        panTo({lat,lng})
                    } catch (error) {
                        console.log(error)
                    }

                    
                }}
            >
                
                <ComboboxInput
                    value={value}
                    className="map-search-box"
                    onChange={(e) => {
                        setValue(e.target.value)
                    }}
                    disabled={!ready}
                    placeholder={t('map.EnterAnAddress')}
                />
                <span class="btn-search"></span>
                <ComboboxPopover style={popoverStyle}>
                    <ComboboxList>
                    {status === "OK" && data.map(({ id, description }) =>
                        <ComboboxOption style={optionsStyle} key={id} value={description} />)}
                    </ComboboxList>
                </ComboboxPopover>
            </Combobox >
        </div>
    )
}