/** @format */

import React from 'react';
import Loader from 'src/components/Loader';
import { useSelector } from 'src/store';

const LoaderWrapper = ({ children }) => {
  const {
    users: { userLoading }
  } = useSelector(state => state);

  const checkForLoading = () => {
    return userLoading;
  };
  return (
    <>
      <Loader loading={checkForLoading()} />
      {children}
    </>
  );
};

export default LoaderWrapper;
