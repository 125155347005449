/** @format */

import React from 'react';
import { SilverBackground } from '../legal-pages/silverBackground';
import newsImage from '../../assets/imgs/Bitmap.png';
import rightArrow from '../../assets/imgs/Triangle.png';
import { VideoContainer } from '../../components/widget/video/video';
import { Link } from 'react-router-dom';
import { CallBtn } from 'src/components/button/callBtn';
import { EmailBtn } from 'src/components/button/emailBtn';
import { Backbtn } from 'src/components/button/backBtn';
import Breadcrumb from 'src/components/Breadcrumb';
const NewsDetails = () => {
    return (
        
            <section className="main_content">
            <Breadcrumb data={[{title: "News"}]} />    
            <SilverBackground title= "News" />
            <div className="content-container container">
                
                <h2 className="content-title">Headline 1</h2>
                <p className="content-block">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Quis assumenda perferendis facilis voluptatibus, quidem cupiditate, autem explicabo quisquam magni ex laborum sint incidunt quibusdam dicta asperiores sit. Odio, architecto aperiam.
                </p>
                <img src={newsImage} alt='headline' className='news-image'/>
                <p className = "news-img-description">
                    Lorem ipsum dolor sit amet.
                </p>
                <div className="headline-two">
                    <h2>Headline 2</h2>
                    <p className="content-block"> Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nihil, laborum?Lorem ipsum dolor sit amet consectetur adipisicing elit. Optio, ut? <br /><br />
                    Lorem ipsum dolor sit amet consectetur, adipisicing elit. Consequatur, repellendus aperiam nulla est qui sapiente eius praesentium esse. Officia, deserunt?</p>
                </div>
                <div className="headline-two">
                    <h2>Headline 2</h2>
                    <p className="content-block"> Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nihil, laborum?Lorem ipsum dolor sit amet consectetur adipisicing elit. Optio, ut? <br /><br />
                    Lorem ipsum dolor sit amet consectetur, adipisicing elit. Consequatur, repellendus aperiam nulla est qui sapiente eius praesentium esse. Officia, deserunt?</p>
                </div>
                <div className="headline-three">
                    <h2>Headline 3</h2>
                    <p className="content-block"> Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nihil, laborum?Lorem ipsum dolor sit amet consectetur adipisicing elit. Optio, ut? <br /><br />
                    Lorem ipsum dolor sit amet consectetur, adipisicing elit. Consequatur, repellendus aperiam nulla est qui sapiente eius praesentium esse. Officia, deserunt?</p>
                </div>
                <Link to="/">
                    <div className="more-news-links">
                        <p> More Links </p>
                        <img src={ rightArrow } alt=""/>
                    </div>
                </Link>
  
            </div>
            <div className="news-container-grid video-container-pro">
                <div className="more-news">
                    <p className="more-title">Weitere News</p>
                    <Link to="/" className="news-container-link">
                        <h3>Headline 1</h3>
                        <p className='more-news-paragraph'>
                            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Minima, laborum?
                        </p>
                    </Link>
                    <Link to="/" className="news-container-link">
                        <h3>Headline 2</h3>
                        <p className='more-news-paragraph'>
                            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Minima, laborum?
                        </p>
                    </Link>
                </div>   
                <VideoContainer videoURL={`https://www.youtube.com/embed/07ePS5Bydc4?controls=0`} videoTitle=""/>
                <div className="news-details-contact">
                    <CallBtn number="+421390090"/>
                    <EmailBtn email="someone@example.com" />
                </div>
                <Backbtn />

            </div>

            </section>
    )
}

export { NewsDetails };
