/** @format */

import * as React from 'react';
import { useTranslation } from 'react-i18next';

export default function SuccessCreateTicket({ closeModal }) {
  const { t } = useTranslation();
  return (
    <div className="create-ticket-status">
      <div className="status-icon">
        <svg width="96" height="96" viewBox="0 0 96 96" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M48 8C25.92 8 8 25.92 8 48C8 70.08 25.92 88 48 88C70.08 88 88 70.08 88 48C88 25.92 70.08 8 48 8ZM48 80C30.36 80 16 65.64 16 48C16 30.36 30.36 16 48 16C65.64 16 80 30.36 80 48C80 65.64 65.64 80 48 80ZM63.52 33.16L40 56.68L32.48 49.16C30.92 47.6 28.4 47.6 26.84 49.16C25.28 50.72 25.28 53.24 26.84 54.8L37.2 65.16C38.76 66.72 41.28 66.72 42.84 65.16L69.2 38.8C70.76 37.24 70.76 34.72 69.2 33.16C67.64 31.6 65.08 31.6 63.52 33.16Z"
            fill="#08C476"
          />
        </svg>
      </div>
      <div className="status-heading">{t('Tickets.successTitle')}</div>
      {/* <div className="ticket-number">
        {t('Tickets.yourCaseNumber')}
        <br />
        DUMMY ID CAS-06477-C3P3R2
      </div> */}
      <div className="status-content">{t('Tickets.successContent')}</div>
      <div className="status-btn">
        <button onClick={closeModal}>{t('Tickets.closeWindow')}</button>
      </div>
    </div>
  );
}
