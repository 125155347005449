/** @format */

import React, { useState, useEffect } from 'react';
import Page from 'src/components/Page';
import { WidthProvider, Responsive } from 'react-grid-layout';
import { useDispatch, useSelector } from 'src/store';
import { Container } from '../../components/container';
import { GetWidget } from 'src/components/widget';
import { getTickets } from 'src/slices/tickets';
import { dummyWidget } from './dummyWidgets';

const ResponsiveReactGridLayout = WidthProvider(Responsive);

const checkSizeName = () => {
  if (window.innerWidth >= 1920) return 'XXL';
  if (window.innerWidth >= 1440) return 'XL';
  if (window.innerWidth >= 1024) return 'LG';
  if (window.innerWidth >= 768) return 'MD';
  if (window.innerWidth >= 576) return 'SM';
  return 'XS';
};

const calcSize = (size, item) => {
  switch (size) {
    case 'small':
      return { w: item.w || 3, h: item.h || 1, minW: 3, maxW: 6, minH: 1, maxH: 4 };
    case 'normal':
      if (checkSizeName() === 'XXL')
        return {
          w: item.w || 3,
          h: item.h || 4,
          minW: item.minW || 3,
          maxW: item.maxW || 9,
          minH: item.minH || 2,
          maxH: item.maxH || 6
        };
      if (checkSizeName() === 'XL')
        return {
          w: item.w || 3,
          h: item.h || 4,
          minW: item.minW || 3,
          maxW: item.maxW || 9,
          minH: item.minH || 2,
          maxH: item.maxH || 6
        };
      if (checkSizeName() === 'LG')
        return {
          w: item.w || 4,
          h: item.h || 4,
          minW: item.minW || 4,
          maxW: item.maxW || 12,
          minH: item.minH || 2,
          maxH: item.maxH || 6
        };
      if (checkSizeName() === 'MD')
        return {
          w: item.w || 1,
          h: item.h || 4,
          minW: item.minW || 1,
          maxW: item.maxW || 2,
          minH: item.minH || 2,
          maxH: item.maxH || 6
        };
      if (checkSizeName() === 'SM')
        return {
          w: item.w || 1,
          h: item.h || 4,
          minW: item.minW || 1,
          maxW: item.maxW || 1,
          minH: item.minH || 2,
          maxH: item.maxH || 6
        };
      if (checkSizeName() === 'XS')
        return {
          w: item.w || 1,
          h: item.h || 4,
          minW: item.minW || 1,
          maxW: item.maxW || 1,
          minH: item.minH || 2,
          maxH: item.maxH || 6
        };
    case 'wide':
      return { w: item.w || 4, h: item.h || 2, minW: 3, maxW: 6, minH: 1, maxH: 4 };
    case 'medium':
      return { w: item.w || 4, h: item.h || 3, minW: 3, maxW: 6, minH: 1, maxH: 4 };
    case 'large':
      return { w: item.w || 6, h: item.h || 3, minW: 3, maxW: 8, minH: 1, maxH: 4 };
    case 'xwide':
      return { w: item.w || 8, h: item.h || 3, minW: 3, maxW: 10, minH: 1, maxH: 4 };
    case 'fullWidth':
      return { w: item.w || 12, h: item.h || 3, minW: 3, maxW: 12, minH: 1, maxH: 4 };
    default:
      return { w: item.w || 4, h: item.h || 2, minW: 3, maxW: 5, minH: 1, maxH: 4 };
  }
};
const generateLayout = (item, index, totalWidthOfItems, changeTotalWidthOfItems, itemPositions) => {
  const { size, x, y } = item;
  changeTotalWidthOfItems(calcSize(size, item).w);
  const layoutRenerated = {
    i: item.i,
    x: item.x != null ? item.x : itemPositions[index] !== null ? itemPositions[index].x : totalWidthOfItems % 12,
    y:
      item.y != null
        ? item.y
        : itemPositions[index] !== null
        ? itemPositions[index].y
        : Math.floor(totalWidthOfItems / 12),
    ...calcSize(size, item)
  };
  return layoutRenerated;
};
let totalWidthOfItems = 0;
const changeTotalWidthOfItems = data => {
  totalWidthOfItems += data;
};
const defaultProps = {
  className: `widgetlayout`,
  rowHeight: 72,
  breakpoints: { xxl: 1441, xl: 1025, lg: 769, md: 577, sm: 376, xs: 0 },
  cols: { xxl: 12, xl: 12, lg: 12, md: 2, sm: 1, xs: 1 },
  margin: [32, 32],
  isDraggable: true,
  isResizable: true
};

export default function WidgetPreview() {
  // const { widgetInfo } = useSelector(state => state.users);
  const [globalLayouts, setLayouts] = useState({});
  const [currentDrag, setCurrentDrag] = useState(null);
  const [itemPositions, setItemPositions] = useState([]);
  const [widgets, setWidgets] = useState(dummyWidget);
  const [currentBreakPoint, setCurrentBreakPoint] = useState(null);

  useEffect(() => {
    const calculateItemPositions = () => {
      const positions = [];
      let currentX = 0;
      let currentY = 0;
      let maxHeightInRow = 0;

      if (widgets.length) {
        widgets.forEach((item, i) => {
          const itemWidth = calcSize(item.size, item).w;
          const itemHeight = calcSize(item.size, item).h;

          const CONTACT_WIDGET = 'contact';
          if (
            !(
              item.type === CONTACT_WIDGET &&
              widgets[i - 1] &&
              widgets[i - 1].type &&
              widgets[i - 1].type === CONTACT_WIDGET
            ) &&
            currentX + itemWidth > 12
          ) {
            currentX = 0;
            currentY += maxHeightInRow;
            maxHeightInRow = 0;
          }

          positions.push({
            x: currentX,
            y: currentY
          });

          currentX += itemWidth;
          if (itemHeight > maxHeightInRow) {
            maxHeightInRow = itemHeight;
          }
        });
      }
      return positions;
    };

    if (widgets.length && itemPositions.length === 0) setItemPositions(calculateItemPositions());
    if (widgets.length && itemPositions.length) {
      const layoutArr = [];
      const newWigets = [];
      widgets.forEach((item, index) => {
        const dataGrid = generateLayout(item, index, totalWidthOfItems, changeTotalWidthOfItems, itemPositions);
        layoutArr.push(dataGrid);
        newWigets.push({
          ...item,
          x: dataGrid.x,
          y: dataGrid.y,
          w: dataGrid.w,
          minW: dataGrid.minW,
          maxW: dataGrid.maxW,
          h: dataGrid.h,
          minH: dataGrid.minH,
          maxH: dataGrid.maxH
        });
      });
      setWidgets(newWigets);
      if (currentBreakPoint) setLayouts({ ...globalLayouts, [currentBreakPoint]: layoutArr });
      else setLayouts({ ...globalLayouts, [Object.keys(globalLayouts)[0]]: layoutArr });
    }
  }, [currentBreakPoint, itemPositions]);

  const onLayoutChange = (currentLayout, allLayouts) => {
    if (Object.keys(globalLayouts).length === 0) setLayouts(allLayouts);
  };
  const onBreakpointChange = (newBreakpoint, newCols) => {
    setCurrentBreakPoint(newBreakpoint);
  };
  const onWidthChange = (containerWidth, margin, cols, containerPadding) => {};

  const onDrop = () => {};
  const onDropDragOver = () => {};
  const onDragStart = () => {};
  const onDrag = () => {};
  const onDragStop = () => {};
  const onResize = (layout, oldItem, newItem, placeholder, e, element) => {
    const newWidget = [...widgets];
    newWidget.find(w => w.i === newItem.i).w = newItem.w;
    newWidget.find(w => w.i === newItem.i).h = newItem.h;
    setWidgets(newWidget);
  };
  const onResizeStop = () => {};

  return (
    <Page className={`dashboard`} title="Dashboard" style={{ marginTop: 40 }}>
      <Container className="widget-container">
        {widgets.length > 0 ? (
          <ResponsiveReactGridLayout
            style={{ margin: 0 }}
            {...defaultProps}
            layouts={globalLayouts}
            droppingItem={currentDrag}
            onDrop={onDrop}
            onDropDragOver={onDropDragOver}
            onLayoutChange={onLayoutChange}
            onDragStart={onDragStart}
            onDrag={onDrag}
            onDragStop={onDragStop}
            onResize={onResize}
            onResizeStop={onResizeStop}
            onBreakpointChange={onBreakpointChange}
            onWidthChange={onWidthChange}>
            {widgets.map((item, index, items) => {
              return (
                <div key={item.i}>
                  <GetWidget data={item} />
                </div>
              );
            })}
          </ResponsiveReactGridLayout>
        ) : null}
      </Container>
    </Page>
  );
}
