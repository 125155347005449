/** @format */

import axios from 'src/utils/axios';
import React, { useEffect, useState } from 'react';
import Helmet from 'react-helmet';

export const DynamicWidgetCard = props => {
  const [widgetScript, setWidgetScript] = useState('');

  useEffect(() => {
    addTokenToWidgetSdk();
    addScriptsHandler();
    addTagHandler();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    addScriptsHandler();
    // eslint-disable-next-line
  }, [props.lang]);

  const addTokenToWidgetSdk = () => {
    //this handler is just to set token for widgets (made by DMG) to access it
    window['customerPortal'] = {
      token: localStorage.getItem('accessToken')
    };
  };

  async function getWidgetScript() {
    const { jsSource } = props.data;
    const widgetScriptSource = jsSource.substring(1, jsSource.length);
    const res = await axios.get(widgetScriptSource, {
      headers: {
        Accept: 'text/javascript'
      }
    });
    const data = res.data;
    return data;
  }

  async function addScriptsHandler() {
    const parentElement = document.getElementById('script-' + props.data.tag);
    if (parentElement) {
      // console.log("REMOVEVEEVEEVVEVE")
      parentElement.remove();
    }
    const data = await getWidgetScript();
    setWidgetScript(data);
    //const srcRoute = process.env.REACT_APP_API_URL + props.data.jsSource;
    //var s = document.createElement('script');
    //s.setAttribute("id", "script-" + props.data.tag)
    //s.setAttribute('src', data);
    //s.setAttribute('type', "text/javascript");
    //document.head.appendChild(s);
  }

  const addTagHandler = () => {
    const parent = document.getElementById(props.data.tag);
    let tag = document.createElement(props.data.tag);
    const currentLanguage = window.localStorage.i18nextLng;
    tag.setAttribute('lang', currentLanguage);
    parent.appendChild(tag);
  };

  return (
    <>
      <div id={props.data.tag} className={`dynamic-widget-card`}>
        <Helmet>
          <script id={'script-' + props.data.tag} type="text/javascript">
            {widgetScript}
          </script>
        </Helmet>
      </div>
    </>
  );
};
