/** @format */

import React, { useEffect } from 'react';
import Page from 'src/components/Page';
import { WelcomeTitle } from '../../components';
import useAuth from 'src/hooks/useAuth';

import { useTranslation } from 'react-i18next';
import Breadcrumb from 'src/components/Breadcrumb';
import SurveyModal from 'src/components/surveyModal';
import { useDispatch, useSelector } from 'src/store';
import { setUserData } from 'src/slices/users';

export const scenarioRequiredFields = {
  driver: ['country'],
  fleet: ['role', 'mainFields', 'country', 'fleetSize', 'transportationType'],
  'workshop-cv': ['role', 'mainFields', 'country', 'sealNumber'],
  'workshop-pv': ['role', 'mainFields', 'country', 'drsId', 'brakeCenterId'],
  partner: ['role', 'mainFields', 'country', 'sealNumber', 'drsId', 'brakeCenterId', 'dealerId'],
  authority: ['mainFields', 'country'],
  trainer: ['role', 'mainFields', 'country'],
  'conti-internal': ['role', 'locationRso', 'segment'],
  'kaas-customer': ['role'],
  'maps-on-demand-customer': [],
  'maps-on-demand-customer-nissan': [],
  'maps-on-demand-customer-renault': [],
  'tolling-proxy-customer': []
};

export const missingRequiredFieldsChecking = user => {
  // Checking scenario and role
  if (!user.scenario) return true;
  if (scenarioRequiredFields[user.scenario] && scenarioRequiredFields[user.scenario].includes('role') && !user.role) {
    return true;
  }
  if (user.role && user.role === 'other' && !user.roleOther) {
    return true;
  }

  // Checking required fields
  if (
    scenarioRequiredFields[user.scenario] &&
    scenarioRequiredFields[user.scenario].includes('country') &&
    !user.address.country
  ) {
    return true;
  }
  if (
    scenarioRequiredFields[user.scenario] &&
    scenarioRequiredFields[user.scenario].includes('mainFields') &&
    (!user?.address?.street || !user?.address?.postalCode || !user?.address?.city || !user?.company)
  ) {
    return true;
  }
  if (!user?.address?.postalCode) {
    return true;
  }
  return false;
};

const SurveyView = () => {
  const { user } = useAuth();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { userData } = useSelector(state => state.users);

  const breadcrumbData = [
    {
      title: t('Survey')
    }
  ];

  useEffect(() => {
    if (user && Object.keys(user).length > 0 && ((userData && Object.keys(userData).length === 0) || !userData)) {
      dispatch(setUserData({ ...user }));
    }
  }, [user, dispatch]);

  return (
    <Page className={`dashboard`} title="Dashboard">
      <Breadcrumb data={breadcrumbData} />
      <WelcomeTitle>
        {user && user.firstName && t('welcome')} {user && user.firstName} {user && user.lastName}
        {user && user.firstName && '!'}
      </WelcomeTitle>
      <SurveyModal />
    </Page>
  );
};

export default SurveyView;
