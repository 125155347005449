/** @format */

import React from 'react';
import { Menu } from 'antd';
import { useTranslation } from 'react-i18next';
import styles from './globalHeader.module.scss';

export const AppsDropdown = props => {
  const { t } = useTranslation();

  return (
    <Menu className={styles.menuDropDown}>
      <Menu.Item key="0">
        <a href="https://my.vdo.com/" target="_blank">
          Customer Portal
        </a>
      </Menu.Item>

      <Menu.Item key="1">
        <a href="https://www.fleet.vdo.com/support/#VDO+Fleet+Login" target="_blank">
          TIS-Web
        </a>
      </Menu.Item>

      <Menu.Item key="2">
        <a href="https://www.fleet.vdo.com/" target="_blank">
          Fleets website
        </a>
      </Menu.Item>

      <Menu.Item key="3">
        <a href="https://vdo-shop.com/" target="_blank">
          VDO Shop
        </a>
      </Menu.Item>

      <Menu.Item key="4">
        <a href="https://www.vdo-academy.com/" target="_blank">
          VDO Academy
        </a>
      </Menu.Item>

      <Menu.Item key="5">
        <a href="https://extranet.continental-partner.com/" target="_blank">
          Extranet
        </a>
      </Menu.Item>
    </Menu>
  );
};
