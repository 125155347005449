/** @format */

import * as React from 'react';
import { useTranslation } from 'react-i18next';
export default function ErrorCreateTicket({ closeModal }) {
  const { t } = useTranslation();
  return (
    <div className="create-ticket-status">
      <div className="status-icon">
        <svg width="96" height="96" viewBox="0 0 96 96" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M48 28C50.2 28 52 29.8 52 32V48C52 50.2 50.2 52 48 52C45.8 52 44 50.2 44 48V32C44 29.8 45.8 28 48 28ZM47.96 8C25.88 8 8 25.92 8 48C8 70.08 25.88 88 47.96 88C70.08 88 88 70.08 88 48C88 25.92 70.08 8 47.96 8ZM48 80C30.32 80 16 65.68 16 48C16 30.32 30.32 16 48 16C65.68 16 80 30.32 80 48C80 65.68 65.68 80 48 80ZM52 68H44V60H52V68Z"
            fill="#DB4F60"
          />
        </svg>
      </div>
      <div className="status-heading">{t('Tickets.errorTitle')}</div>
      <div className="status-content">{t('Tickets.errorContent')}</div>
      <div className="status-btn">
        <button onClick={closeModal}>{t('Tickets.closeWindow')}</button>
      </div>
    </div>
  );
}
