/** @format */

import { isNumber } from 'lodash';
import { Link } from 'react-router-dom';
import React from 'react';

export const ContactWidget = props => {
  const data = props.data;
  const image = process.env.REACT_APP_API_URL + data.image;
  const linkRedirect = data.link;
  const internalLink = data.nid;
  const linkTitle = data && data.linkTitle.length > 1 ? data.linkTitle : '';

  return (
    <>
      <div className={`contactWidget ${internalLink ? `contact-link` : ``}`}>
        <img src={image} alt="card-icon" />
        <div className="card-body">
          <h2 className="card-title">{data.title}</h2>
          {internalLink ? (
            <Link className="card-text" to={`/page/public/${internalLink}`}>
              {linkTitle}
            </Link>
          ) : (
            <a
              className="card-text"
              href={linkRedirect && linkRedirect.includes('@') ? `mailto:${linkRedirect}` : `tel:${linkRedirect}`}>
              {linkTitle}
            </a>
          )}
        </div>
      </div>
    </>
  );
};
