import React from 'react';
import Breadcrumb from 'src/components/Breadcrumb';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Chip, Button, IconButton } from '@material-ui/core';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import TransitEnterexitIcon from '@material-ui/icons/TransitEnterexit';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

const useStyles = makeStyles((theme) => ({
    root: {
        fontSize: "1.5rem",
        maxWidth: "70%",
        margin: "2rem auto 0 auto"
    },
    chip: {
        background: 'rgba(0, 0, 0, 0.15)',
        color: '#000',
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '13px',
        lineHeight: '17px'
    },
    deleteButton: {
        marginLeft: '1rem',
        color: '#8D8D8D',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '14px',
        lineHeight: '16px'
    },
    iconButton: {
        color: '#000',
        fontSize: '14px',
    },
    iconButton2: {
        color: '#000',
        fontSize: '14px',
        transform: 'rotate(180deg)'
    }
}))

const Notifications = () => {
    const { t } = useTranslation();
    const classes = useStyles();

    const breadcrumbData = [
        {
            title: t('menu.notifications')
        }
    ];

    const notificationsList = [
        {
            title: "Customer Portal",
            subTitle: "Please renew your license before it expires in 14 days.",
            type: "external",
        },
        {
            title: "My E-Learning",
            subTitle: "Your training DTCO 4.0 starts tomorrow.",
            type: "internal",
        },
        {
            title: "VDO link",
            subTitle: "Check your hardware ID XYZ. It is offline. ",
            type: "internal",
        },
    ];

    const NotificationItem = ({data}) => {
        const getIconButton = () => {
            if(data?.type === "external") {
                return <IconButton className={classes.iconButton2}>
                <TransitEnterexitIcon />
            </IconButton>
            }
            return <IconButton className={classes.iconButton}>
            <KeyboardArrowRightIcon />
        </IconButton>
        }
        return (
            <>
                <div className={'saved-item-box'} >
                    <div className='saved-item-description'>
                        <span className='saved-item-title'><FiberManualRecordIcon className='dot'/> {data?.title}</span>
                        <span className='saved-item-sub-title'>{data?.subTitle}</span>
                    </div>
                    <div>
                    <span className="saved-item-time-text">1 min ago</span>
                    {getIconButton()}
                    </div>
                </div>
            </>
        )
    }

    return (
        <>
            <Breadcrumb data={breadcrumbData} />
            <div className='container'>
                <div className='welcome-title'>
                    <h2 className='uppercase'>{`${t('menu.notifications')}`}</h2>
                </div>

                <Grid container className={classes.root} spacing={2}>

                    {notificationsList?.map(item => (
                        <>
                        <Grid item sm={12}>
                            <NotificationItem data={item} />
                        </Grid>
                    </>
                    ))}

                </Grid>
            </div>
        </>
    )
}

export default Notifications