/** @format */

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { currencyFormat } from 'src/components/utils';

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

export function ShopWidget({ data }) {
  const { t } = useTranslation();
  const [slidesPerView, setSlidesPerView] = useState(1);
  useEffect(() => {
    setSlidesPerView(1);
    if (data.w === 5 || data.w === 6) setSlidesPerView(2);
    if (data.w === 7 || data.w === 8) setSlidesPerView(3);
    if (data.w === 9) setSlidesPerView(4);
  }, [data.w]);

  return (
    <div className="shop-widget" data-width={data.w} data-height={data.h}>
      <div className="shop-widget--title">
        <h2>{data.title} </h2>
      </div>
      <div className="shop-widget--list-wrapper">
        <div className="shop-widget--list">
          {data.shop ? (
            data.shop.length > 0 ? (
              <Slider accessibility={false} dots infinite speed={500} slidesToShow={slidesPerView} slidesToScroll={1}>
                {data.shop.map(({ id, image, title, category, price, priceSale }) => (
                  <div className="shop-widget--list-item" key={id}>
                    <div className="shop-widget--list-item-image">
                      <img src={image} alt="" />
                      <div className="shop-widget--list-item-category">{category}</div>
                    </div>
                    <div className="shop-widget--list-item-title">{title}</div>
                    <div className="shop-widget--list-item-price">statt {currencyFormat(price)}</div>
                    <div className="shop-widget--list-item-priceSale">{currencyFormat(priceSale)}</div>
                  </div>
                ))}
              </Slider>
            ) : (
              <p className="shop-widget--list-empty">{t('widget.noProducts')}</p>
            )
          ) : data.orders ? (
            <>
              <div className="shop-widget--order-title">
                <div className="shop-widget--order-item-number">{t('widget.shopNumber')}</div>
                <div className="shop-widget--order-item-date">{t('widget.shopDate')}</div>
                <div className="shop-widget--order-item-price">{t('widget.shopPrice')}</div>
                <div className="shop-widget--order-item-status">{t('widget.shopStatus')}</div>
                <div className="shop-widget--order-item-action">{t('widget.shopAction')}</div>
              </div>
              {data.orders.map(({ id, number, date, status, price }) => (
                <div className="shop-widget--order-item" key={id}>
                  <div className="shop-widget--order-item-number">
                    <a href="#">{number}</a>
                  </div>
                  <div className="shop-widget--order-item-date">{date}</div>
                  <div className="shop-widget--order-item-price">{currencyFormat(price)}</div>
                  <div className="shop-widget--order-item-status">
                    <span className={status.replace(' ', '')}>{status}</span>
                  </div>
                  <div className="shop-widget--order-item-action">
                    <a href="#">Tracking</a>
                  </div>
                </div>
              ))}
            </>
          ) : null}
        </div>
      </div>
      {data.shop ? (
        <div className="shop-widget--link">
          <a href="/shop">{t('widget.goToShop')}</a>
        </div>
      ) : data.orders ? (
        <div className="shop-widget--link">
          <a href="/orders">{t('widget.allOrders')}</a>
        </div>
      ) : null}
    </div>
  );
}
