/** @format */

import React, { useEffect, useState } from 'react';
import { Dropdown } from 'antd';
import { Avatar } from './avatar';
import styles from './globalHeader.module.scss';

import logoutIcon from './icons/logout.svg';
import languageIcon from './icons/language.svg';
import myProfileIcon from './icons/myProfile.svg';
import { useTranslation } from 'react-i18next';
import LanguageSelectorModal from '../LanugageSelectorModal';
import { Link } from 'react-router-dom';
import { UserDropdown } from './userDropdown';
import { updateUserProfilePicture } from 'src/slices/users';
import { useDispatch, useSelector } from 'src/store';

export const UserInfo = props => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { userProfile } = useSelector(state => state.users);
  const [lanugageSelectorToggle, setLanguageSelectorToggle] = useState(false);

  useEffect(() => {
    if (!userProfile || !userProfile.picture) {
      dispatch(updateUserProfilePicture());
    }
  }, []);

  const languageSelectorHandler = event => {
    event.preventDefault();
    setLanguageSelectorToggle(true);
  };

  let user = props.data;
  return (
    <div className={styles.userInfo}>
      <div className={styles.userInfoDetail}>
        <p className={styles.userInfoDetail__name}>
          {user && user.firstName && `${user?.firstName} ${user?.lastName}`}
        </p>
      </div>
      <Dropdown
        overlay={() => <UserDropdown user={user} logout={props.logout} onLangChange={props.onLangChange} />}
        trigger={['click']}
        placement={'bottomRight'}
        overlayClassName={styles.userInfoDropDown}>
        <button className={styles.userInfoDropDownButton}>
          <Avatar srcImg={userProfile?.picture || ''} name={`${user?.firstName} ${user?.lastName}`} />
        </button>
      </Dropdown>
      <LanguageSelectorModal
        open={lanugageSelectorToggle}
        onClose={() => {
          setLanguageSelectorToggle(false);
        }}
        onLangChange={data => {
          props.onLangChange(data);
        }}
      />
    </div>
  );
};
