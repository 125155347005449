/** @format */

import React from 'react';
import { useTranslation } from 'react-i18next';

export function PartnerFinderWidget({ data }) {
  const { t } = useTranslation();
  return (
    <div className="partnerFinder-widget" data-width={data.w} data-height={data.h}>
      <div className="partnerFinder-widget--title">
        <h2>{data.title}</h2>
      </div>
      <div className="partnerFinder-widget--search">
        <input placeholder="Munich" />
        <p>
          <strong>Or</strong>
        </p>
        <a href="" className="partnerFinder-widget--detect-automation">
          {t('widget.useYourCurrentLocation')}
        </a>
      </div>
      <div className="partnerFinder-widget--list-wrapper">
        <h3 className="partnerFinder-widget--list-title">{t('widget.specification')}</h3>
        <div className="partnerFinder-widget--list">
          {data.terms.map(term => {
            return (
              <div className="partnerFinder-widget--list-item" key={term}>
                {term}
              </div>
            );
          })}
        </div>
      </div>
      <div className="partnerFinder-widget--link">
        <a href="#">{t('widget.goToContinentalPartner')}</a>
      </div>
    </div>
  );
}
