/** @format */

import React, {useState} from 'react';
import { useTranslation  } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import {Grid, Button, TextField, Select, FormControl, InputLabel, MenuItem} from '@material-ui/core'
import Map from './map';
import useAuth from 'src/hooks/useAuth';
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    root: {
      fontSize:"1.5rem",
      maxWidth:"70%",
      margin:"0 auto"
    },
    textColor:{
        color:"#999",
        lineHeight:"2.5rem",
        paddingTop:"1rem"
    },
    btnwrapper:{
        marginTop:15
    },
    linkcolor:{
        color:"#08C476",
        fontSize:"3.5rem",
        verticalAlign:"middle",
    },
    btnStyle:{
        border:"2px solid #333",
        borderRadius:25,
        fontSize:"1.2rem",
        color:"#333",
        fontWeight:"700",
        '&:hover': {
            background:"transparent",
            border:"2px solid #333",        
        }
    },
    delbtnStyle:{
        marginLeft:"1.5rem",
        borderRadius:25,
        fontSize:"1.2rem",
        color:"#999",
        fontWeight:"700",
        '&:hover': {
            background:"transparent",
        }
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        '& > *':{
            fontSize:"1.5rem",
            color:"#333"
        },
        '& > .MuiInput-underline':{
            '&:before':{
                borderBottom: '2px solid #234D96',
            }
        },
        '& > .MuiInputLabel-formControl':{
            color: "#234D96"
        }
      },
      textField_2: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width:"95%",
        '& > *':{
            fontSize:"1.5rem",
            color:"#333"
        },
        '& > .MuiInput-underline':{
            '&:before':{
                borderBottom: '2px solid #234D96',
            }
        },
        '& > .MuiInputLabel-formControl':{
            color: "#234D96"
        }
      },
      footerBtn:{
        marginTop:25,
        textAlign:"right"
      },
      abortBtn:{
        marginRight:"1.5rem",
        borderRadius:25,
        fontSize:"1.2rem",
        color:"#234D96",
        fontWeight:"700",
        '&:hover': {
            background:"transparent",
        }
      },
      formControl: {
        marginTop: theme.spacing(2),
        marginLeft: theme.spacing(1),
        minWidth: "100%",
        '& > *':{
            fontSize:"1.5rem",
            color:"#333"
        },
        '& > .MuiInput-underline':{
            '&:before':{
                borderBottom: '2px solid #234D96',
            }
        },
        '& > .MuiInputLabel-formControl':{
            color: "#234D96"
        }
      },
      selectEmpty: {
        marginTop: theme.spacing(2),
        fontSize:"1.5rem",
      },  
  }));
  
const CompanyData = (props) => {
    const {user} = useAuth();
    const classes = useStyles();
    let history = useHistory();
    const [gender, setGender] = useState('man');
    const [country, setCountry] = useState('Germany');
    const { t } = useTranslation();

    const handleChange = (event) => {
        setGender(event.target.value);
      };
    const handleChangeCountry = (event) => {
        setCountry(event.target.value);
    };
    const goBack = ()=>{
        history.push('/profile')
    }
    return (
        <section className="main_content profile">
            <div className="content-container services-container container">
            
            <h2 className='page_title'>{t('companyData.title')}</h2>
            <h4 className='page_subtitle'>{t('profile.subTitle')}</h4>    
            
            <Grid container className={classes.root} spacing={2}>
                <Grid item xs={12}>
                    <h4 className='sub_head'>{t('companyData.ContactPerson')}</h4>
                    <Grid container className={classes.cardHead}>
                        <Grid item xs={2}>
                                <FormControl className={classes.formControl}>
                                    <InputLabel id="gender">{t('companyData.gender.title')}</InputLabel>
                                    <Select
                                    labelId="gender"
                                    id="gender"
                                    value={gender}
                                    onChange={handleChange}
                                    >
                                    <MenuItem value="man">{t('companyData.gender.man')}</MenuItem>
                                    <MenuItem value="woman">{t('companyData.gender.woman')}</MenuItem>
                                    <MenuItem value="others">{t('companyData.gender.others')}</MenuItem>
                                    </Select>
                                </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                                <TextField
                                id="standard-full-width"
                                label={t('personalData.firstName')}
                                placeholder={t('personalData.firstName')}
                                fullWidth
                                className={classes.textField}
                                value={user?.firstName}
                                margin="normal"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                />
                        </Grid>
                        <Grid item xs={12}>
                                <TextField
                                id="standard-full-width"
                                label={t('personalData.Surname')}
                                placeholder={t('personalData.Surname')}
                                fullWidth
                                className={classes.textField}
                                value={user?.lastName}
                                margin="normal"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                />
                        </Grid>
                        <Grid item xs={12}>
                                <TextField
                                id="standard-full-width"
                                label={t('companyData.E_Mail_Company')}
                                placeholder={t('companyData.E_Mail_Company')}
                                fullWidth
                                className={classes.textField}
                                margin="normal"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                />
                        </Grid>
                        <Grid item xs={12}>
                                <span style={{fontSize:"14px", color:"#999"}}>* {t('companyData.Mandatory_field')}</span>
                        </Grid>
                        <Grid item xs={12}>
                            <div className={classes.footerBtn}>
                                <Button className={classes.btnStyle} variant="outlined" color="primary">{t('companyData.requestBtn')}</Button>
                            </div>
                        </Grid>
                    </Grid>
                    <h4 className='sub_head'>{t('companyData.companyaddress')}</h4>
                    <Grid container className={classes.cardHead}>
                        <Grid item xs={12}>
                                <TextField
                                id="standard-full-width"
                                label={t('companyData.Street')}
                                placeholder={t('companyData.Street')}
                                fullWidth
                                className={classes.textField}
                                margin="normal"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                />
                        </Grid>
                        <Grid item xs={3}>
                                <TextField
                                id="standard-full-width"
                                label={t('companyData.Postcode')}
                                placeholder={t('companyData.Postcode')}
                                className={classes.textField_2}
                                margin="normal"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                />
                        </Grid>
                        <Grid item xs={3}>
                                <TextField
                                id="standard-full-width"
                                label={t('companyData.city')}
                                placeholder={t('companyData.city')}
                                className={classes.textField_2}
                                margin="normal"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                />
                        </Grid>
                        <Grid item xs={3}>
                                <TextField
                                id="standard-full-width"
                                label={t('companyData.PO_Box_Mailbox')}
                                placeholder={t('companyData.PO_Box_Mailbox')}
                                className={classes.textField_2}
                                margin="normal"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                />
                        </Grid>
                        <Grid item xs={3}>
                                <FormControl className={classes.formControl}>
                                    <InputLabel id="country">{t('companyData.country')}</InputLabel>
                                    <Select
                                    labelId="country"
                                    id="country"
                                    value={country}
                                    onChange={handleChangeCountry}
                                    >
                                    <MenuItem value="Germany">Germany</MenuItem>
                                    <MenuItem value="India">India</MenuItem>
                                    <MenuItem value="Australia">Australia</MenuItem>
                                    </Select>
                                </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                                <TextField
                                id="standard-full-width"
                                label={t('companyData.phone')}
                                placeholder={t('companyData.phone')}
                                className={classes.textField_2}
                                margin="normal"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                />
                        </Grid>
                        <Grid item xs={6}>
                                <TextField
                                id="standard-full-width"
                                label={t('companyData.Faxnumber')}
                                placeholder={t('companyData.Faxnumber')}
                                className={classes.textField_2}
                                margin="normal"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                />
                        </Grid>
                        <Grid item xs={12}>
                                <TextField
                                id="standard-full-width"
                                label={t('companyData.phone')}
                                placeholder={t('companyData.phone')}
                                fullWidth
                                className={classes.textField}
                                margin="normal"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                />
                        </Grid>
                        <Grid item xs={12}>
                                <span style={{fontSize:"14px", color:"#999"}}>* {t('companyData.Mandatory_field')}</span>
                        </Grid>
                        <Grid item xs={12}>
                            <div className={classes.footerBtn}>
                                <Button className={classes.btnStyle} variant="outlined" color="primary">{t('companyData.requestBtn')}</Button>
                            </div>
                        </Grid>
                    </Grid>
                    <h4 className='sub_head'>{t('companyData.Additional_Information')}</h4>
                    <Grid container className={classes.cardHead}>
                        <Grid item xs={12}>
                                <TextField
                                id="standard-full-width"
                                label={t('companyData.TAX_VAT_Number')}
                                placeholder={t('companyData.TAX_VAT_Number')}
                                fullWidth
                                className={classes.textField}
                                margin="normal"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                />
                        </Grid>
                        <Grid item xs={12}>
                                <TextField
                                id="standard-full-width"
                                label={t('companyData.seal_number')}
                                placeholder={t('companyData.seal_number')}
                                fullWidth
                                className={classes.textField}
                                margin="normal"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                />
                        </Grid>
                        <Grid item xs={12}>
                                <TextField
                                id="standard-full-width"
                                label={t('companyData.Diesel_repair_service_id')}
                                placeholder={t('companyData.Diesel_repair_service_id')}
                                fullWidth
                                className={classes.textField}
                                margin="normal"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                />
                        </Grid>
                        <Grid item xs={12}>
                            <div className={classes.footerBtn}>
                                <Button onClick={goBack} className={classes.abortBtn}>{t('personalData.Abortbtn')}</Button>
                                {''}
                                <Button className={classes.btnStyle} variant="outlined" color="primary">{t('personalData.saveBtn')}</Button>
                            </div>
                        </Grid>
                    </Grid>
                    <h4 className='sub_head'>{t('companyData.mapHeading')}</h4>
                    <p>{t('companyData.Maptext')}</p>
                    <Grid container className={classes.cardHead}>
                        <Grid item xs={12}>
                            <Map/>
                        </Grid>
                        <Grid item xs={12}>
                            <div className={classes.footerBtn}>
                                <Button className={classes.abortBtn}>{t('companyData.deleteMapBtn')}</Button>
                                {''}
                                <Button className={classes.btnStyle} variant="outlined" color="primary">{t('companyData.saveMapBtn')}</Button>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
                
            </Grid>
       

                

            </div>

        </section>
    )
}

export { CompanyData };
