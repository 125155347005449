/** @format */

import React from 'react';
import { Container } from '../container';
// dashborad welcome text
export const WelcomeTitle = props => {
  return (
    <Container>
      <h2 className="welcome-title" {...props}>
        {props.children}
      </h2>{' '}
    </Container>
  );
};
