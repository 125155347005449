/** @format */

import { createSlice } from '@reduxjs/toolkit';
// import _ from 'lodash';
import axios from 'src/utils/axios';
import { ENDPOINTS } from 'src/constants';

const initialState = {
  ticketLoading: false,
  updateTicketLoading: false,
  ticketDetail: null,
  tickets: null,
  error: null
};

const slice = createSlice({
  name: 'tickets',
  initialState,
  reducers: {
    //Get tickets
    getTickets(state) {
      state.ticketLoading = true;
    },
    getTicketsSuccess(state, action) {
      const data = action.payload;
      state.tickets = data.value || [];
      state.ticketLoading = false;
    },
    getTicketsFailure(state, action) {
      state.error = action.payload;
      state.ticketLoading = false;
    },
    getTicketDetail(state, action) {
      state.ticketLoading = true;
    },
    getTicketDetailSuccess(state, action) {
      const data = action.payload;
      state.ticketDetail = data || {};
      state.ticketLoading = false;
    },
    getTicketDetailFailure(state, action) {
      state.error = action.payload;
      state.ticketLoading = false;
    },
    createTicket(state, action) {
      state.updateTicketLoading = true;
    },
    createTicketSuccess(state, action) {
      state.updateTicketLoading = false;
    },
    createTicketFailure(state, action) {
      state.error = action.payload;
      state.updateTicketLoading = false;
    },
    updateTicket(state, action) {
      state.updateTicketLoading = true;
    },
    updateTicketSuccess(state, action) {
      state.updateTicketLoading = false;
    },
    updateTicketFailure(state, action) {
      state.error = action.payload;
      state.updateTicketLoading = false;
    }
  }
});

export const reducer = slice.reducer;

// Tickets
export const getTickets = lang => async dispatch => {
  const { getTickets, getTicketsSuccess, getTicketsFailure } = slice.actions;
  // debugger
  try {
    dispatch(getTickets());
    if (lang) {
      const response = await axios.get(`${ENDPOINTS.GET_TICKETS_ENDOIUNT}/?limit=100`, {
        headers: {
          'Accept-Language': lang
        }
      });
      dispatch(getTicketsSuccess(response.data));
    }
  } catch (err) {
    dispatch(getTicketsFailure(err));
  }
};

export const getTicketDetail = (lang, id) => async dispatch => {
  const { getTicketDetail, getTicketDetailSuccess, getTicketDetailFailure } = slice.actions;
  // debugger
  try {
    dispatch(getTicketDetail());
    if (lang) {
      const response = await axios.get(`${ENDPOINTS.GET_TICKETS_ENDOIUNT}/${id}`, {
        headers: {
          'Accept-Language': lang
        }
      });
      dispatch(getTicketDetailSuccess(response.data));
    }
  } catch (err) {
    dispatch(getTicketDetailFailure(err));
  }
};

export const createTicket = postData => async dispatch => {
  const { createTicket, createTicketSuccess, createTicketFailure } = slice.actions;
  try {
    dispatch(createTicket());
    const response = await axios.post(ENDPOINTS.GET_TICKETS_ENDOIUNT, postData);
    if (!response.error) dispatch(createTicketSuccess(response.data));
  } catch (err) {
    dispatch(createTicketFailure(err));
  }
};

export const updateTicket = postData => async dispatch => {
  const { updateTicket, updateTicketSuccess, updateTicketFailure } = slice.actions;
  try {
    dispatch(updateTicket());
    const response = await axios.patch(`${ENDPOINTS.GET_TICKETS_ENDOIUNT}/${postData.incidentid}`, postData);
    if (!response.error) dispatch(updateTicketSuccess(response.data));
  } catch (err) {
    dispatch(updateTicketFailure(err));
  }
};

export default slice;
