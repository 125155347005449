/** @format */

import React from 'react';
import { SilverBackground } from '../legal-pages/silverBackground';
// import newsImage from '../../assets/imgs/Bitmap.png';
import { VideoContainer } from '../../components/widget/video/video';
// import { Link } from 'react-router-dom';
import { CallBtn } from 'src/components/button/callBtn';
import { EmailBtn } from 'src/components/button/emailBtn';
// import { Backbtn } from 'src/components/button/backBtn';
import DownloadPdf from '../../assets/imgs/download.png';
import Breadcrumb from 'src/components/Breadcrumb';


const ServicesDetails = () => {
    return (
        <section className="main_content">
            <Breadcrumb data={[{title: 'Services'}]} />
            <SilverBackground title= "Services" />
            <div className="content-container services-container container">
            <h2 className="content-title">Headline 1</h2>
                <p className="content-block">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Quis assumenda perferendis facilis voluptatibus, quidem cupiditate, autem explicabo quisquam magni ex laborum sint incidunt quibusdam dicta asperiores sit. Odio, architecto aperiam.
                </p>
            <VideoContainer />
            <p className = "news-img-description">
                    Lorem ipsum dolor sit amet.
            </p>

       
            <div className="headline-two">
                    <h2>Headline 2</h2>
                    <p className="content-block"> Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nihil, laborum?Lorem ipsum dolor sit amet consectetur adipisicing elit. Optio, ut? <br /><br />
                    Lorem ipsum dolor sit amet consectetur, adipisicing elit. Consequatur, repellendus aperiam nulla est qui sapiente eius praesentium esse. Officia, deserunt?</p>
                </div>
                <div className="headline-two">
                    <h2>Headline 2</h2>
                    <p className="content-block"> Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nihil, laborum?Lorem ipsum dolor sit amet consectetur adipisicing elit. Optio, ut? <br /><br />
                    Lorem ipsum dolor sit amet consectetur, adipisicing elit. Consequatur, repellendus aperiam nulla est qui sapiente eius praesentium esse. Officia, deserunt?</p>
                </div>
                <div className="headline-three">
                    <h2>Headline 3</h2>
                    <p className="content-block"> Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nihil, laborum?Lorem ipsum dolor sit amet consectetur adipisicing elit. Optio, ut? <br /><br />
                    Lorem ipsum dolor sit amet consectetur, adipisicing elit. Consequatur, repellendus aperiam nulla est qui sapiente eius praesentium esse. Officia, deserunt?</p>
                </div>

                <div className="headline-two">
                <h2>Downloads</h2>
                    <div className="download-container">
                        <div className="download-box">
                        <img src={ DownloadPdf }  className="download-btn" alt=""/>
                            <div className="download-details">
                                <p className="document-name">Names de Document</p>
                                <p className="document-size">128 kb</p>
                            </div>
                        </div>
                        <div className="download-box">
                        <img src={ DownloadPdf }  className="download-btn" alt=""/>
                            <div className="download-details">
                                <p className="document-name">Names de Document</p>
                                <p className="document-size">128 kb</p>
                            </div>
                        </div>
                        <div className="download-box">
                        <img src={ DownloadPdf }  className="download-btn" alt=""/>
                            <div className="download-details">
                                <p className="document-name">Names de Document</p>
                                <p className="document-size">128 kb</p>
                            </div>
                        </div>
                    </div>

                </div>

                <div className="headline-two">
                <h2>Support</h2>
                <div className="support-container">
                    <EmailBtn email="someone@example.com" />
                    <CallBtn number="+421390090" />
                </div>

                </div>
            </div>

        </section>
    )
}

export { ServicesDetails };
