/** @format */

import React from 'react';
import { Link } from 'react-router-dom';
import getRedirectionLink from 'src/utils/helper';

export function LinkWidget({ data }) {
  const image = process.env.REACT_APP_API_URL + data.image;

  return (
    <div className="link-widget" data-width={data.w} data-height={data.h}>
      <div className="link-widget--list-item">
        <div className="link-widget--list-item-image">
          <img src={image} alt={data.title} />
          {data.category && <div className="link-widget--list-item-category">{data.category}</div>}
        </div>
        <div className="link-widget--list-item-title">{data.title}</div>
        <div className="link-widget--list-item-text">{data.content}</div>
        <div className="link-widget--list-item-link">
          {data.link ? (
            <a href={data.link} target={`${data.linkTarget === '_blank' ? '_blank' : ''}`}>
              {data.linkTitle}
            </a>
          ) : (
            <Link to={getRedirectionLink(data)}>{data.linkTitle}</Link>
          )}
        </div>
      </div>
    </div>
  );
}
