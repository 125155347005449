/** @format */

import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'react-perfect-scrollbar/dist/css/styles.css';
import './style/style.scss';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { enableES5 } from 'immer';
import * as serviceWorker from 'src/serviceWorker';
import store from 'src/store';
import { SettingsProvider } from 'src/contexts/SettingsContext';
import LoaderWrapper from 'src/utils/LoaderWrapper';
import { AuthProvider } from 'src/contexts/KeyClockContext';
import App from 'src/App';
// import i18n from './i18n'
// const Storage = window.localStorage

let lang = Storage.i18nextLng
// const browserLanguage = navigator.language.split("-")[0];
// i18n.changeLanguage(browserLanguage)
enableES5();

ReactDOM.render(
  <Provider store={store}>
    <SettingsProvider>
      <LoaderWrapper>
        <AuthProvider>
          <App />
        </AuthProvider>
      </LoaderWrapper>
    </SettingsProvider>
  </Provider>,
  document.getElementById('root')
);

serviceWorker.register();
document.getElementsByTagName('html')[0].setAttribute("lang", lang);