/** @format */

export const Logo = require('./assets/imgs/logo.svg');
export const FooterLink = [
  { title: 'Contact', link: '/news' },
  { title: 'Cookies', link: '/' },
  { title: 'Privacy', link: '/' },
  { title: 'Terms of Use', link: '/' },
  { title: 'Imprint', link: '/' }
];
export const NoImg = require('src/assets/imgs/no-image.jpeg');
export const userData = {
  profile: '',
  name: 'Max Mustermann',
  title: 'Fleet Manager',
  userclass: 'MVP' // has { mvp, vision }
};

// export const siteList = [
//   { title: 'Customer Portal', url: '' },
//   { title: 'TIS-Web', url: '' },
//   { title: 'Fleets Website', url: '' },
//   { title: 'VDO Shop', url: '' },
//   { title: 'VDO Academy', url: '' },
//   { title: 'Extranet', url: '' }
// ];
