/** @format */

import axios from 'axios';
import { keyClockClient } from '../contexts/KeyClockContext';

const { REACT_APP_API_URL } = process.env;

const axiosInstance = axios.create({
  baseURL: REACT_APP_API_URL
});

axiosInstance.interceptors.request.use(async (config) => {
  const newConfigs = { ...config };
  if (keyClockClient.token) {
    const isTokenExpired = await keyClockClient.isTokenExpired();
    if (isTokenExpired) {
      await keyClockClient.updateToken().catch((error) => { console.error(error) });
      localStorage.setItem('accessToken', keyClockClient.token);
      localStorage.setItem('expirationTime', keyClockClient?.refreshTokenParsed?.exp);
      axiosInstance.defaults.headers.common.Authorization = `Bearer ${keyClockClient.token}`;
      newConfigs.headers.common = { ...config.headers.common, "Authorization": `Bearer ${keyClockClient.token}` };
    }
  }
  
  return newConfigs;
}, error => {
  return Promise.reject('Something went wrong');
});

axiosInstance.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    return Promise.reject((error.response && error.response.data) || 'Something went wrong');
  }
);

export default axiosInstance;
