/** @format */

const getRedirectionLink = item => {
  if (item?.nid) {
    if (item.public) {
      return `/page/public/${item?.nid}`;
    }
    return `/page/${item?.nid}`;
  }
  if (item.link) {
    return item.link;
  }
  return '/404';
};

export const getFileBase64 = file =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
  });

export default getRedirectionLink;
