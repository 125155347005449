/** @format */

import React from 'react';
import {
  ContactWidget,
  ExtranetWidget,
  EventsWidget,
  MultiVideosWidget,
  MagazineWidget,
  NewsWidget,
  LinkWidget,
  InfoWidget,
  ShopWidget,
  VideoContainer,
  DynamicWidgetCard,
  CardMultiLink,
  MobilityWidget,
  PartnerFinderWidget,
  AcademyWidget
} from './cards';
import TicketsWidget from './tickets/ticketsWidget';

const GetWidget = ({ data }) => {
  const handleWidgetType = data => {
    switch (data.type) {
      case 'contact': //can be whatever this for testing
        return <ContactWidget data={data} />;
      case 'video': //can be whatever this for testing
        return <MultiVideosWidget data={data} />;
      case 'dynamic':
        return <DynamicWidgetCard data={data} lang={window.localStorage.i18nextLng} />;
      case 'multi_link':
        return <CardMultiLink data={data} lang={window.localStorage.i18nextLng} />;
      case 'tickets':
        return <TicketsWidget data={data} lang={window.localStorage.i18nextLng} />;
      case 'extranet':
        return <ExtranetWidget data={data} />;
      case 'events':
        return <EventsWidget data={data} />;
      case 'magazine':
        return <MagazineWidget data={data} />;
      case 'news':
        return <NewsWidget data={data} />;
      case 'link':
        return <LinkWidget data={data} />;
      case 'info':
        return <InfoWidget data={data} />;
      case 'shop':
        return <ShopWidget data={data} />;
      case 'mobility':
        return <MobilityWidget data={data} />;
      case 'partnerFinder':
        return <PartnerFinderWidget data={data} />;
      case 'academy':
        return <AcademyWidget data={data} />;
      default:
        return 'No type widget match!'; // when not found that widget *can be whatever
    }
  };

  return data && handleWidgetType(data);
};

export { GetWidget };
