/** @format */

import React from 'react';

export const Container = props => {
  return (
    <div className="container" {...props}>
      {props.children}
    </div>
  );
};
