/** @format */

import React, { useEffect } from 'react';

import { useDispatch, useSelector } from 'src/store';
import { getFooterLinksInfo } from 'src/slices/global';
import { Link } from 'react-router-dom';
// import { getBuildVersion } from 'src/utils/version';
import useAuth from 'src/hooks/useAuth';

export const Footer = () => {
  const dispatch = useDispatch();
  const { user } = useAuth();
  const { footerLinks } = useSelector(state => state.global);

  useEffect(() => {
    const Storage = window.localStorage;
    let lang = Storage.i18nextLng;
    if (user?.locale) {
      dispatch(getFooterLinksInfo(lang));
    }
    // eslint-disable-next-line
  }, []);

  const getRedirectURL = item => {
    if (item?.nid) {
      if (item.public) {
        return `/page/public/${item?.nid}`;
      }
      return `/page/${item?.nid}`;
    }
    return '/404';
  };

  const currentYear = new Date().getFullYear();

  // const BuildVersion = () => {
  //   return <p align="end" className="buildVersion">{getBuildVersion()}</p>;
  // };

  return (
    <>
      <footer>
        <div className="container">
          <div className="footer-inner">
            <span className="footer-copyright">
              © Continental AG <span>{currentYear || '2023'}</span>
            </span>
            {/* <BuildVersion /> */}
            <ul className="footer-link">
              {footerLinks &&
                footerLinks.map((item, index) => {
                  return (
                    <li key={index}>
                      <Link to={getRedirectURL(item)}>{item.title}</Link>
                    </li>
                  );
                })}
            </ul>
          </div>
        </div>
      </footer>
    </>
  );
};
