/** @format */

import useScrollReset from 'src/hooks/useScrollReset';

const ScrollReset = () => {
  useScrollReset();

  return null;
};

export default ScrollReset;
