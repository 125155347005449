/** @format */

import { DialogTitle, Grid, IconButton, TextField, Typography } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import CloseIcon from '@material-ui/icons/Close';
import * as React from 'react';
import CancelCreateTicketModal from './cancel';
import SuccessCreateTicket from './success';
import ErrorCreateTicket from './error';
import { useTranslation } from 'react-i18next';
import { Button } from 'src/components/button';
import useAuth from 'src/hooks/useAuth';
import SelectComponent from 'src/components/select';
import { DeleteIcon, UploadIcon } from 'src/components/icons';
import { VALIDATE_REGEX } from 'src/constants';
import fileSize from 'filesize';
import { useDispatch, useSelector } from 'src/store';
import { createTicket, getTickets } from 'src/slices/tickets';
import { getFileBase64 } from 'src/utils/helper';

const initPostData = {
  title: null,
  contactName: null,
  contactEmail: null,
  requestType: null,
  dealerAssociation: null,
  impact: null,
  urgency: null,
  typeOfProblem: null,
  component: null,
  endCustomerAccount: null,
  feature: null,
  affectedEmailAddress: null,
  affectedVehicle: null,
  affectedDriver: null,
  model: null,
  softwareVersion: null,
  deviceserialnumber: null,
  vehicleRegistrationNumber: null,
  type: null,
  companyCardNumber: null,
  description: null,
  attachment: null,
  attachmentFileName: null
};
const deviceData = [
  {
    value: 'R-DL 4G',
    name: 'R-DL 4G'
  },
  {
    value: 'DLD WR II',
    name: 'DLD WR II'
  }
];
const typeData = [
  {
    value: 'Authentication',
    name: 'Authentication'
  },
  {
    value: 'Download',
    name: 'Download'
  }
];
const modelData = [
  { value: 'DLK Smart', name: 'DLK Smart' },
  { value: 'DLK Pro S', name: 'DLK Pro S' },
  { value: 'DLK Pro', name: 'DLK Pro' },
  { value: 'DLK II', name: 'DLK II' },
  { value: 'DLK', name: 'DLK' }
];
const impactData = [
  { value: 'Extensive', name: 'Extensive' },
  { value: 'Significant', name: 'Significant' },
  { value: 'Moderate', name: 'Moderate' },
  { value: 'Minor', name: 'Minor' }
];
const urgencyData = [
  { value: 'High', name: 'High' },
  { value: 'Medium', name: 'Medium' },
  { value: 'Low', name: 'Low' }
];

export default function CreateTicketModal({ open, onClose }) {
  const { t } = useTranslation();
  const { user } = useAuth();
  const [isAdmin, setIsAdmin] = React.useState(false);
  const [isOtherPerson, setIsOtherPerson] = React.useState(false);
  const [cancelModalOpen, setCancelModalOpen] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [postData, setPostData] = React.useState(initPostData);
  const [isValidate, setIsValidate] = React.useState(false);
  const [componentData, setComponentData] = React.useState([]);
  const [featureData, setFeatureData] = React.useState([]);
  const [files, setFiles] = React.useState([]);
  const dispatch = useDispatch();
  const { userData } = useSelector(state => state.users);
  const { updateTicketLoading, error: errorTicket } = useSelector(state => state.tickets);
  const isProduction = process.env.REACT_APP_API_URL === 'https://backend.my.vdo.com/';

  React.useEffect(() => {
    if (user && userData) {
      setPostData({
        ...postData,
        customerId: user.company || 'B2C dummy',
        endCustomerAccount: user.firstName + ' ' + user.lastName,
        accountName: user.firstName + ' ' + user.lastName,
        zip: user.address?.postalCode || userData.address?.postalCode || '',
        contactName: user.firstName + ' ' + user.lastName,
        contactEmail: user.email,
        affectedEmailAddress: user.email
      });
      if (user.roles?.includes('admin')) setIsAdmin(true);
    }
  }, [open, user, userData]);
  React.useEffect(() => {
    if (success && !updateTicketLoading) {
      setPostData(initPostData);
    }
  }, [success, updateTicketLoading]);

  React.useEffect(() => {
    setIsValidate(false);
    switch (postData.typeOfProblem) {
      case null:
        if (
          !isOtherPerson &&
          postData.title &&
          postData.requestType &&
          postData.dealerAssociation &&
          postData.impact &&
          postData.urgency
        ) {
          setIsValidate(true);
          break;
        }
      // if (isOtherPerson && postData.requestType && postData.dealerAssociation) {
      //   setIsValidate(true);
      //   break;
      // }
      case 'VDO Fleet':
        if (
          postData.component === 'Login' &&
          postData.endCustomerAccount &&
          postData.affectedEmailAddress &&
          VALIDATE_REGEX.email.test(postData.affectedEmailAddress) &&
          postData.description
        ) {
          setIsValidate(true);
          break;
        }
        if (postData.component === 'Motion/Maps' && postData.endCustomerAccount && postData.description) {
          setIsValidate(true);
          break;
        }
        if (
          postData.component === 'VDO Fleet App' &&
          postData.endCustomerAccount &&
          postData.description &&
          postData.feature
        ) {
          setIsValidate(true);
          break;
        }
        if (postData.component === 'Extract' && postData.endCustomerAccount && postData.description) {
          setIsValidate(true);
          break;
        }
        if (postData.component === 'Connect' && postData.endCustomerAccount && postData.description) {
          setIsValidate(true);
          break;
        }
        if (postData.component === 'Admin' && postData.endCustomerAccount && postData.description && postData.feature) {
          setIsValidate(true);
          break;
        }
        if (
          postData.component === 'Tachograph Management' &&
          postData.endCustomerAccount &&
          postData.description &&
          postData.feature
        ) {
          setIsValidate(true);
          break;
        }
        if (
          postData.component === 'Remote Download' &&
          postData.endCustomerAccount &&
          postData.description &&
          postData.deviceserialnumber &&
          postData.type
        ) {
          setIsValidate(true);
          break;
        }
      case 'Hardware':
        if (
          postData.component === 'R-DL 4G' &&
          postData.endCustomerAccount &&
          postData.description &&
          postData.vehicleRegistrationNumber &&
          postData.deviceserialnumber &&
          postData.type
        ) {
          setIsValidate(true);
          break;
        }
        if (
          postData.component === 'DLD WR II' &&
          postData.endCustomerAccount &&
          postData.description &&
          postData.vehicleRegistrationNumber &&
          postData.deviceserialnumber &&
          postData.type
        ) {
          setIsValidate(true);
          break;
        }
        if (
          postData.component === 'Download Key' &&
          postData.endCustomerAccount &&
          postData.description &&
          postData.model &&
          postData.deviceserialnumber
        ) {
          setIsValidate(true);
          break;
        }
        if (postData.component === 'Smart Terminal' && postData.endCustomerAccount && postData.description) {
          setIsValidate(true);
          break;
        }
      default:
        break;
    }
  }, [postData]);
  React.useEffect(() => {
    switch (postData.typeOfProblem) {
      case 'VDO Fleet':
        if (isAdmin)
          setComponentData([
            { value: 'Login', name: 'Login' },
            { value: 'Motion/Maps', name: 'Motion/Maps' },
            { value: 'VDO Fleet App', name: 'VDO Fleet App' },
            { value: 'Extract', name: 'Extract' },
            { value: 'Connect', name: 'Connect' },
            { value: 'Admin', name: 'Admin' },
            { value: 'Tachograph Management', name: 'Tachograph Management' },
            { value: 'Remote Download', name: 'Remote Download' }
          ]);
        else
          setComponentData([
            { value: 'Login', name: 'Login' },
            { value: 'Motion/Maps', name: 'Motion/Maps' },
            { value: 'VDO Fleet App', name: 'VDO Fleet App' },
            { value: 'Extract', name: 'Extract' },
            { value: 'Connect', name: 'Connect' },
            { value: 'Tachograph Management', name: 'Tachograph Management' },
            { value: 'Remote Download', name: 'Remote Download' }
          ]);
        break;
      case 'Hardware':
        setComponentData([
          { value: 'R-DL 4G', name: 'R-DL 4G' },
          { value: 'DLD WR II', name: 'DLD WR II' },
          { value: 'Download Key', name: 'Download Key' },
          { value: 'Smart Terminal', name: 'Smart Terminal' }
        ]);
        break;
      default:
        break;
    }
  }, [postData.typeOfProblem]);
  React.useEffect(() => {
    switch (postData.component) {
      case 'VDO Fleet App':
        setFeatureData([
          { value: 'Download/Upload', name: 'Download/Upload' },
          { value: 'Maps', name: 'Maps' },
          { value: 'Rownload Reminder', name: 'Download Reminder' },
          { value: 'Application', name: 'Application' }
        ]);
        break;
      case 'Admin':
        setFeatureData([
          { value: 'Account Management', name: 'Account Management' },
          { value: 'Active Directory', name: 'Active Directory' },
          { value: 'Admin Tool Mock', name: 'Admin Tool Mock' },
          { value: 'Billing', name: 'Billing' },
          { value: 'Login Page', name: 'Login Page' },
          { value: 'Infrastructure', name: 'Infrastructure' },
          { value: 'Migration', name: 'Migration' },
          { value: 'Report', name: 'Report' },
          { value: 'Statistic', name: 'Statistic' },
          { value: 'Translation', name: 'Translation' }
        ]);
      case 'Tachograph Management':
        setFeatureData([
          { value: 'Automatic Upload', name: 'Automatic Upload' },
          { value: 'Billing', name: 'Billing' },
          { value: 'Dashboard', name: 'Dashboard' },
          { value: 'DHE', name: 'DHE' },
          { value: 'Driver', name: 'Driver' },
          { value: 'Event Alerts', name: 'Event Alerts' },
          { value: 'File Upload', name: 'File Upload' },
          { value: 'Hours Engine', name: 'Hours Engine' },
          { value: 'Infringement', name: 'Infringement' },
          { value: 'Legal Archive', name: 'Legal Archive' },
          { value: 'Master Data', name: 'Master Data' },
          { value: 'Others', name: 'Others' },
          { value: 'Reminder', name: 'Reminder' },
          { value: 'Report', name: 'Report' },
          { value: 'Retrieval', name: 'Retrieval' }
        ]);
        break;
      default:
        break;
    }
  }, [postData.component]);

  const updatePostData = (name, value) => {
    setPostData({ ...postData, [name]: value });
  };
  const handleSelectFiles = async e => {
    setFiles(files.concat(Array.from(e.target.files)));
    if (e.target.files && e.target.files[0]) {
      const attachmentFileName = e.target.files[0].name;
      const attachment = await getFileBase64(e.target.files[0]);
      setPostData({ ...postData, attachmentFileName, attachment });
    }
    // e.target.files = [];
    // e.target.value = null;
  };
  const removeFile = index => {
    files.splice(index, 1);
    setFiles([...files]);
    setPostData({ ...postData, attachmentFileName: null, attachment: null });
  };
  const closeNewTicket = () => {
    if (!updateTicketLoading) {
      onClose(false);
      setCancelModalOpen(false);

      // avoid flash of layout
      setTimeout(() => {
        setPostData(initPostData);
        setError(false);
        setSuccess(false);
        setFiles([]);
      }, 300);
    }
  };
  const handleClose = () => {
    if (!updateTicketLoading) {
      if (success) closeNewTicket();
      else setCancelModalOpen(true);
    }
  };
  const handleSubmit = async () => {
    const createTicketPostData = {
      title: isProduction ? postData.title : `(TEST) ${postData.title}`,
      description: postData.description,
      customerid: postData.customerId,
      accountName: postData.endCustomerAccount,
      zip: postData.zip,
      contactName: postData.contactName,
      contactEmail: postData.contactEmail,
      project: postData.component,
      impact: postData.impact,
      urgency: postData.urgency,
      smy_affecteddriver: postData.affectedDriver || postData.affectedEmailAddress || '',
      smy_Project: '',
      smy_SubProject: '',
      smy_deviceserialnumber: postData.deviceserialnumber,
      smy_companycardnumber: postData.companyCardNumber,
      smy_model: postData.model,
      smy_softwareversion: postData.softwareVersion,
      components: ['Remote Download', 'R-DL 4G', 'DLD WR II'].includes(postData.component)
        ? postData.type
        : postData.feature,
      ava_endcustomer: '',
      ava_endcustomercontact: '',
      attachment: postData.attachment,
      attachmentFileName: postData.attachmentFileName,
      vrnnumber: postData.component === 'Motion/Maps' ? postData.affectedVehicle : postData.vehicleRegistrationNumber
    };
    await dispatch(createTicket(createTicketPostData));
    setError(false);
    setSuccess(true);

    const Storage = window.localStorage;
    const lang = Storage.i18nextLng || 'en';
    await dispatch(getTickets(lang));
  };

  return (
    <>
      <Dialog
        fullWidth
        maxWidth="md"
        open={open}
        PaperProps={{
          style: {
            backgroundColor: '#fff',
            borderRadius: 10
          }
        }}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title">
        <DialogActions>
          <IconButton onClick={handleClose} disabled={updateTicketLoading}>
            <CloseIcon style={{ color: '#000' }} />
          </IconButton>
        </DialogActions>
        <DialogContent>
          <div className="create-ticket-body">
            {error ? (
              <ErrorCreateTicket closeModal={closeNewTicket} />
            ) : success ? (
              <SuccessCreateTicket closeModal={closeNewTicket} />
            ) : (
              <>
                <Typography className="create-ticket-title">{t('Tickets.newTicket')}</Typography>
                {postData.typeOfProblem === null && (
                  <>
                    <div className="contact-person-heading">{t('Tickets.contactPerson')}</div>
                    {user && (
                      <div className="contact-person">
                        <div className="contact-person-left">
                          <p className="contact-name">
                            {user.firstName} {user.lastName}
                          </p>
                          <p className="contact-email">{user.email}</p>
                          <p className="contact-tel">{user.address?.phoneNumber || ''}</p>
                        </div>
                        <div className="contact-person-right">
                          <p className="contact-address">{user.company}</p>
                          {user.address && (
                            <>
                              <p className="contact-street">
                                {user.address.street} {user.address.postBox}
                              </p>
                              <p className="contact-city">
                                {user.address.country} {user.address.postalCode} {user.address.city}
                              </p>
                            </>
                          )}
                        </div>
                      </div>
                    )}
                    <div className="contact-person-checkbox">
                      <div className="contact-person-checkbox-flex">
                        <input
                          type="checkbox"
                          id="contact-person-checkbox"
                          onChange={() => setIsOtherPerson(!isOtherPerson)}
                        />
                        <label htmlFor="contact-person-checkbox">{t('Tickets.checkboxLabelNewTicket')}</label>
                      </div>
                      {isOtherPerson && (
                        <Grid container style={{ marginTop: 10 }}>
                          <Grid item xs={12}>
                            <TextField
                              // className="product-detail-input"
                              className="create-ticket-text-field"
                              fullWidth
                              id="other-person-name"
                              label={t('Tickets.name')}
                              variant="standard"
                              style={{ marginBottom: 10 }}
                              onChange={e => updatePostData('contactName', e.target.value)}
                            />
                            <TextField
                              // className="product-detail-input"
                              className="create-ticket-text-field"
                              fullWidth
                              id="other-person-email"
                              label={t('Tickets.email')}
                              variant="standard"
                              onChange={e => updatePostData('contactEmail', e.target.value)}
                            />
                          </Grid>
                        </Grid>
                      )}
                    </div>
                    <Grid container>
                      <Grid item xs={12}>
                        <Grid style={{ marginBottom: 10 }}>
                          <SelectComponent
                            label="Request type *"
                            options={[
                              { value: 'service', name: 'Service/Support & Product Request' },
                              { value: 'sales', name: 'Sales request' },
                              { value: 'logistics', name: 'Logistics/Accounting request' }
                            ]}
                            onChange={e => updatePostData('requestType', e.target.value)}
                            value={postData.requestType}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    {postData.requestType && (
                      <>
                        <Grid container>
                          <Grid item xs={12}>
                            <Grid style={{ marginBottom: 10 }}>
                              <TextField
                                className="create-ticket-text-field"
                                label="Title *"
                                onChange={e => updatePostData('title', e.target.value)}
                                value={postData.title || ''}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid container>
                          <Grid item xs={12}>
                            <Grid style={{ marginBottom: 10 }}>
                              <TextField
                                className="create-ticket-text-field"
                                label="Dealer Association *"
                                onChange={e => updatePostData('dealerAssociation', e.target.value)}
                                value={postData.dealerAssociation || ''}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid container>
                          <Grid item xs={12}>
                            <Grid style={{ marginBottom: 10 }}>
                              <SelectComponent
                                label="Impact *"
                                options={impactData}
                                onChange={e => updatePostData('impact', e.target.value)}
                                value={postData.impact}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid container>
                          <Grid item xs={12}>
                            <Grid style={{ marginBottom: 10 }}>
                              <SelectComponent
                                label="Urgency *"
                                options={urgencyData}
                                onChange={e => updatePostData('urgency', e.target.value)}
                                value={postData.urgency}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </>
                    )}
                  </>
                )}
                {postData.typeOfProblem !== null && (
                  <>
                    <Grid container>
                      <Grid item xs={12}>
                        <Grid style={{ marginBottom: 10 }}>
                          <SelectComponent
                            label="Component *"
                            options={componentData}
                            onChange={e => updatePostData('component', e.target.value)}
                            value={postData.component}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid item xs={12}>
                        <Grid style={{ marginBottom: 10 }}>
                          <TextField
                            className="create-ticket-text-field"
                            label="End-customer Account *"
                            onChange={e => updatePostData('endCustomerAccount', e.target.value)}
                            value={postData.endCustomerAccount || ''}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    {postData.component === 'Login' && (
                      <Grid container>
                        <Grid item xs={12}>
                          <Grid style={{ marginBottom: 10 }}>
                            <TextField
                              className="create-ticket-text-field"
                              label="Affected Email Address *"
                              onChange={e => updatePostData('affectedEmailAddress', e.target.value)}
                              value={postData.affectedEmailAddress || ''}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    )}
                    {postData.component === 'Motion/Maps' && (
                      <>
                        <Grid container>
                          <Grid item xs={12}>
                            <Grid style={{ marginBottom: 10 }}>
                              <TextField
                                className="create-ticket-text-field"
                                label="Affected Vehicle (VRN)"
                                onChange={e => updatePostData('affectedVehicle', e.target.value)}
                                value={postData.affectedVehicle || ''}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid container>
                          <Grid item xs={12}>
                            <Grid style={{ marginBottom: 10 }}>
                              <TextField
                                className="create-ticket-text-field"
                                label="Affected Driver (Name)"
                                onChange={e => updatePostData('affectedDriver', e.target.value)}
                                value={postData.affectedDriver || ''}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </>
                    )}
                    {['VDO Fleet App', 'Admin', 'Tachograph Management'].includes(postData.component) && (
                      <>
                        <Grid container>
                          <Grid item xs={12}>
                            <Grid style={{ marginBottom: 10 }}>
                              <SelectComponent
                                label="Feature *"
                                options={featureData}
                                onChange={e => updatePostData('feature', e.target.value)}
                                value={postData.feature}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </>
                    )}
                    {['R-DL 4G', 'DLD WR II'].includes(postData.component) && (
                      <>
                        <Grid container>
                          <Grid item xs={12}>
                            <Grid style={{ marginBottom: 10 }}>
                              <TextField
                                className="create-ticket-text-field"
                                label="Vehicle Registration Number *"
                                onChange={e => updatePostData('vehicleRegistrationNumber', e.target.value)}
                                value={postData.vehicleRegistrationNumber || ''}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </>
                    )}
                    {postData.component === 'Download Key' && (
                      <>
                        <Grid container>
                          <Grid item xs={12}>
                            <Grid style={{ marginBottom: 10 }}>
                              <SelectComponent
                                label="Model *"
                                options={modelData}
                                onChange={e => updatePostData('model', e.target.value)}
                                value={postData.model}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid container>
                          <Grid item xs={12}>
                            <Grid style={{ marginBottom: 10 }}>
                              <TextField
                                className="create-ticket-text-field"
                                label="Software Version"
                                onChange={e => updatePostData('softwareVersion', e.target.value)}
                                value={postData.softwareVersion || ''}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </>
                    )}
                    {postData.component === 'Smart Terminal' && (
                      <>
                        <Grid container>
                          <Grid item xs={12}>
                            <Grid style={{ marginBottom: 10 }}>
                              <TextField
                                className="create-ticket-text-field"
                                label="Model"
                                onChange={e => updatePostData('model', e.target.value)}
                                value={postData.model || ''}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </>
                    )}
                    {['Remote Download', 'R-DL 4G', 'DLD WR II', 'Download Key'].includes(postData.component) && (
                      <>
                        <Grid container>
                          <Grid item xs={12}>
                            <Grid style={{ marginBottom: 10 }}>
                              <TextField
                                className="create-ticket-text-field"
                                label="Device Serial Number *"
                                onChange={e => updatePostData('deviceserialnumber', e.target.value)}
                                value={postData.deviceserialnumber || ''}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </>
                    )}
                    {['Remote Download', 'R-DL 4G', 'DLD WR II'].includes(postData.component) && (
                      <>
                        <Grid container>
                          <Grid item xs={12}>
                            <Grid style={{ marginBottom: 10 }}>
                              <SelectComponent
                                label="Type *"
                                options={typeData}
                                onChange={e => updatePostData('type', e.target.value)}
                                value={postData.type}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid container>
                          <Grid item xs={12}>
                            <Grid style={{ marginBottom: 10 }}>
                              <TextField
                                className="create-ticket-text-field"
                                label="Company Card Number"
                                onChange={e => updatePostData('companyCardNumber', e.target.value)}
                                value={postData.companyCardNumber || ''}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </>
                    )}
                    <Grid container>
                      <Grid item xs={12}>
                        <Grid style={{ marginBottom: 30 }}>
                          <TextField
                            className="create-ticket-textarea"
                            multiline
                            label="Description *"
                            onChange={e => updatePostData('description', e.target.value)}
                            value={postData.description || ''}
                            variant="outlined"
                            minRows={4}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    {files.length === 0 && (
                      <Grid container>
                        <Grid item xs={12}>
                          <Grid style={{ marginBottom: 10 }}>
                            <Button
                              disabled={updateTicketLoading}
                              noArrow
                              borderOnly
                              additionalClassName="visually-hidden-input">
                              <label htmlFor="attachment">
                                <UploadIcon /> Error Message/Screenshot (jpg, png)
                              </label>
                              <input
                                accept="image/png, image/gif, image/jpeg, image/jpg"
                                id="attachment"
                                type="file"
                                // multiple
                                onChange={handleSelectFiles}
                              />
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    )}
                    {files.length > 0 && (
                      <Grid container>
                        <Grid item xs={12}>
                          <Grid style={{ marginBottom: 10 }}>
                            <h4>Selected file</h4>
                            <div className="file-list">
                              {files.map((file, fileIndex) => (
                                <div className="file-item" key={`${fileIndex}-${file.name}-${file.size}`}>
                                  {file.name} ({fileSize(file.size)})
                                  <button onClick={() => removeFile(fileIndex)} disabled={updateTicketLoading}>
                                    <DeleteIcon />
                                  </button>
                                </div>
                              ))}
                            </div>
                          </Grid>
                        </Grid>
                      </Grid>
                    )}
                  </>
                )}
                {/* THIS IS OLD BUT CLIENT THEY HAVE NOT CONFIRMED YET!!!
                <div>
                  <div className="product-details-heading">{t('Tickets.productDetails')}</div>
                  <p>TIS - Web / Download Error / Driver</p>
                  <TextField
                    className="product-detail-input"
                    fullWidth
                    id="ticket-title"
                    label={t('Tickets.caseTitle')}
                    variant="standard"
                  />
                  <TextField
                    className="product-detail-textarea"
                    multiline
                    fullWidth
                    id="ticket-describe"
                    label={t('Tickets.descriptionPlaceholder')}
                    variant="outlined"
                    minRows={8}
                  />
                </div>
                */}
                {postData.typeOfProblem === null && postData.requestType && (
                  <div className="create-ticket-btns-step-1">
                    <Button
                      borderOnly
                      disabled={!isValidate}
                      onClick={() => updatePostData('typeOfProblem', 'VDO Fleet')}>
                      VDO Fleet
                    </Button>
                    <Button
                      borderOnly
                      disabled={!isValidate}
                      onClick={() => updatePostData('typeOfProblem', 'Hardware')}>
                      Hardware
                    </Button>
                  </div>
                )}
                <div className="create-ticket-btns">
                  {postData.typeOfProblem !== null && (
                    <>
                      <button
                        disabled={updateTicketLoading}
                        className="btn-restart"
                        onClick={() => setPostData(initPostData)}>
                        {t('Tickets.restartProcess')}
                      </button>
                      <button
                        disabled={updateTicketLoading}
                        onClick={() => setPostData({ ...postData, typeOfProblem: null, component: null })}
                        className="btn-border btn-prev">
                        {t('Tickets.back')}
                      </button>
                    </>
                  )}
                  {postData.typeOfProblem !== null && (
                    <Button
                      disabled={!isValidate || updateTicketLoading}
                      borderOnly
                      onClick={handleSubmit}
                      additionalClassName="btn-submit">
                      {updateTicketLoading ? t('personalData.loading') : t('Tickets.submit')}
                    </Button>
                  )}
                </div>
              </>
            )}
          </div>
        </DialogContent>
      </Dialog>
      <CancelCreateTicketModal
        onClose={() => setCancelModalOpen(false)}
        open={cancelModalOpen}
        closeNewTicket={closeNewTicket}
      />
    </>
  );
}
