/** @format */

import React, { createContext, useEffect, useReducer } from 'react';
import SplashScreen from 'src/components/SplashScreen';
import { keyclockConfig } from 'src/config';
import Keycloak from 'keycloak-js';
import axios from 'src/utils/axios';
import { ENDPOINTS } from 'src/constants';
import i18n from 'src/i18n';

export let keyClockClient = new Keycloak({
  ...keyclockConfig
});

const initialAuthState = {
  isAuthenticated: false,
  isInitialised: false,
  user: null,
  role: null
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'INITIALISE': {
      const { isAuthenticated } = action.payload;

      return {
        ...state,
        isAuthenticated,
        isInitialised: true
      };
    }
    case 'LOGIN': {
      const { user, role } = action.payload;
      return {
        ...state,
        isAuthenticated: true,
        user,
        role: role
      };
    }
    case 'LOGOUT': {
      return {
        ...state,
        isAuthenticated: false,
        user: null
      };
    }
    default: {
      return { ...state };
    }
  }
};

const AuthContext = createContext({
  ...initialAuthState
});

const setSession = (accessToken, expirationTime) => {
  if (accessToken) {
    localStorage.setItem('accessToken', accessToken);
    localStorage.setItem('expirationTime', expirationTime);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('expirationTime');
    delete axios.defaults.headers.common.Authorization;
  }
};

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialAuthState);

  const onLogin = lang => {
    keyClockClient.login({ locale: lang });
  };

  const logout = () => {
    setSession(null, null);
    keyClockClient.logout();
  };

  const getUserInfo = async roles => {
    const response = await axios.get(ENDPOINTS.GET_USER_INFO_ENDPOINT);
    const user = response.data;
    user['email'] = keyClockClient.tokenParsed.email;
    dispatch({
      type: 'LOGIN',
      payload: {
        user,
        role: roles[0]
      }
    });
  };

  const isTokenValid = () => {
    const token = localStorage.getItem('accessToken');
    const expiresIn = localStorage.getItem('expirationTime');
    if (token && expiresIn && expiresIn > Math.round(new Date() / 1000)) {
      // console.log('VALID');
      return true;
    }
    // console.log('INVALID');
    setSession(keyClockClient.refreshToken, keyClockClient?.refreshTokenParsed?.exp);
    // debugger
    dispatch({
      type: 'LOGOUT',
      payload: {}
    });
    return false;
  };

  useEffect(() => {
    const initialise = async () => {
      try {
        // keyClockClient = new Keycloak({
        //   ...keyclockConfig
        // });
        // const token = localStorage.getItem('accessToken');
        await keyClockClient.init({
          // onLoad: isTokenValid() ? 'login-required' : '',
          // redirectUri: isTokenValid() ? window.location.href : window.location.origin
          onLoad: keyClockClient.authenticated ? 'login-required' : 'check-sso',
          redirectUri: window.location.href,
          silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html'
        });
        const isAuthenticated = await keyClockClient.authenticated;
        const keyClockAccessRoles = keyClockClient?.idTokenParsed?.roles || [];
        //console.log('KEYCLILENT::::::::', keyClockClient, keyClockAccessRoles);
        if (isAuthenticated) {
          setSession(keyClockClient.token, keyClockClient?.refreshTokenParsed?.exp);
          if (keyClockAccessRoles.length > 0) {
            getUserInfo(keyClockAccessRoles);
          }
          // Here you should extract the complete user profile to make it available in your entire app.
          // The auth state only provides basic information.
          dispatch({
            type: 'INITIALISE',
            payload: {
              isAuthenticated,
              langKeycloak: keyClockClient.tokenParsed.locale
            }
          });
          if (keyClockAccessRoles.length > 0) {
            dispatch({
              type: 'LOGIN',
              payload: {
                user: {},
                role: keyClockAccessRoles[0] || [],
                langKeycloak: keyClockClient.tokenParsed.locale
              }
            });
          }
        } else {
          dispatch({
            type: 'INITIALISE',
            payload: {
              isAuthenticated,
              user: null
            }
          });

          // if((window.location.href !== window.location.origin + "/")) {
          //   keyClockClient.login({redirectUri: window.location.href, locale: i18n.language})
          // }
        }

        //Token Refresh
        // setInterval(() => {
        //   keyClockClient.updateToken(60).then((refreshed) => {
        //     if (refreshed) {
        //       console.log('Token refreshed' + refreshed);
        //     } else {
        //       console.log('Token not refreshed, valid for '
        //         + Math.round(keyClockClient.tokenParsed.exp + keyClockClient.timeSkew - new Date().getTime() / 1000) + ' seconds');
        //     }
        //   }).catch(() => {
        //     console.error('Failed to refresh token');
        //   });
        // }, 20000)
        // Token Refresh Ends
      } catch (err) {
        // console.error(err);
        dispatch({
          type: 'INITIALISE',
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
      }
    };

    initialise();
  }, []);

  if (!state.isInitialised) {
    return <SplashScreen />;
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        onLogin,
        logout
      }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
