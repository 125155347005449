/** @format */

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactPlayer from 'react-player';

export function MultiVideosWidget({ data }) {
  const [play, setPlay] = useState(false);
  const { t } = useTranslation();
  return (
    <div className="video-widget" data-width={data.w} data-height={data.h}>
      <div className="video-widget--title">
        <h2>{data.title} </h2>
      </div>
      <div className="video-widget--list-wrapper">
        <div className="video-widget--list">
          {data.videos ? (
            data.videos?.length > 0 ? (
              data.videos.map(({ link, content, time, date, category }, i) => (
                <div className="video-widget--list-item" key={i}>
                  <div className="video-widget--list-item-video">
                    <ReactPlayer
                      className="resp-iframe"
                      url={link}
                      playing={play}
                      width={`100%`}
                      height={`100%`}
                      onPause={() => setPlay(false)}
                      controls={true}
                    />
                  </div>
                  <div className="video-widget--list-item-title">{content}</div>
                  <div className="video-widget--list-item-info">
                    <div className="video-widget--list-item-time">{time || '00:00'}</div>
                    <div className="video-widget--list-item-date">{date || '00.00.0000'}</div>
                    <div className="video-widget--list-item-category">{category || 'category'}</div>
                  </div>
                </div>
              ))
            ) : (
              <p className="video-widget--list-empty">{t('widget.noVideos')}</p>
            )
          ) : data.link ? (
            <div className="video-widget--list-item">
              <div className="video-widget--list-item-video">
                <ReactPlayer
                  className="resp-iframe"
                  url={data.link}
                  playing={play}
                  width={`100%`}
                  height={`100%`}
                  onPause={() => setPlay(false)}
                  controls={true}
                />
              </div>
              <div className="video-widget--list-item-title">{data.content}</div>
              <div className="video-widget--list-item-info">
                <div className="video-widget--list-item-time">{data.time || '00:00'}</div>
                <div className="video-widget--list-item-date">{data.date || '00.00.0000'}</div>
                <div className="video-widget--list-item-category">{data.category || 'category'}</div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
      <div className="video-widget--link">
        <a href="/videos">{t('widget.allVideos')}</a>
      </div>
    </div>
  );
}
