/** @format */

import React from 'react';
import email from '../../assets/imgs/card-icons/email.svg';


const EmailBtn = (props) => {
  return (
    <a href={`mailto:${props.email}`}>
      <div>
          <button className="call-btn-blue"> 
            <img src={ email } alt=""/>
            E-MAIL
          </button>
      </div>
    </a>
  );
};

export { EmailBtn };
// mailto:someone@example.com