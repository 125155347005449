/** @format */

import { createSlice } from '@reduxjs/toolkit';
// import _ from 'lodash';
import axios from 'src/utils/axios';
import { ENDPOINTS } from 'src/constants';

const initialState = {
  globalLoading: false,
  footerLinks: null,
  publicContentInfo: null,
  privateContentInfo: null,
  error:null
};

const slice = createSlice({
  name: 'global',
  initialState,
  reducers: {

    //Get Footer Links

    getFooterLinks(state) {
      state.globalLoading = true;
    },
    getFooterLinksSuccess(state, action) {
      const { data } = action.payload;
      state.footerLinks = data.data;
      state.globalLoading = false;
    },
    getFooterLinksFailure(state, action) {
      const { error } = action.payload;
      state.error = error;
      state.globalLoading = false;
    },

    //Get Public Content

    getPublicContent(state) {
      state.globalLoading = true;
    },
    getPublicContentSuccess(state, action) {
      const { data } = action.payload;
      state.publicContentInfo = data;
      state.globalLoading = false;
    },
    getPublicContentFailure(state, action) {
      // const { error } = action.payload;
      state.error = action.payload;
      state.globalLoading = false;
    },

    //Get Private Content

    getPrivateContent(state) {
      state.globalLoading = true;
    },
    getPrivateContentSuccess(state, action) {
      const { data } = action.payload;
      state.privateContentInfo = data;
      state.globalLoading = false;
    },
    getPrivateContentFailure(state, action) {
      const { error } = action.payload;
      state.error = error;
      state.globalLoading = false;
    },
  }
});

export const reducer = slice.reducer;

export const getFooterLinksInfo = (lang) => async dispatch => {
  const { getFooterLinks, getFooterLinksSuccess, getFooterLinksFailure } = slice.actions;

  try {
    dispatch(getFooterLinks());
    if(lang){
    const response = await axios.get(ENDPOINTS.GET_FOOTER_LINKS_ENDPOINTS, {
      headers: {
        "Accept-Language": lang
      }
     });
    dispatch(getFooterLinksSuccess(response));
    }
  } catch (err) {
    dispatch(getFooterLinksFailure(err));
  }
};

export const getPublicContentInfo = (id, lang, onSuccess, onFailure) => async dispatch => {
  const { getPublicContent, getPublicContentSuccess, getPublicContentFailure } = slice.actions;

  try {
    dispatch(getPublicContent());
    const response = await axios.get(`${ENDPOINTS.GET_PUBLIC_CONTENT_INFO_ENDPOINT}/${id}`, {
      headers: {
        "Accept-Language": lang
      }
     });
    dispatch(getPublicContentSuccess(response));
    onSuccess(response.data);
  } catch (err) {
    dispatch(getPublicContentFailure(err));
    // onFailure(err);
  }
};

export const getPrivateContentInfo = (id, lang, onSuccess, onFailure) => async dispatch => {
  const { getPrivateContent, getPrivateContentSuccess, getPrivateContentFailure } = slice.actions;

  try {
    dispatch(getPrivateContent());
    const response = await axios.get(`${ENDPOINTS.GET_PRIVATE_CONTENT_INFO_ENDPOINT}/${id}`, {
      headers: {
        "Accept-Language": lang
      }
     });
    dispatch(getPrivateContentSuccess(response));
    onSuccess(response.data);
  } catch (err) {
    dispatch(getPrivateContentFailure(err));
    onFailure(err);
  }
};
export const resetError =()=>async dispatch =>{
  const {getPublicContentFailure } = slice.actions;
  dispatch(getPublicContentFailure(null));
}
export default slice;
