/** @format */

import React from 'react';

export const Badge = props => {
  return (
    <div className={`badge ${props.className ? props.className : ``}`}>
      <span>{props.label}</span>
    </div>
  );
};
