/** @format */

export const marketMap = new Map();
marketMap.set('at', 'Austria');
marketMap.set('de', 'Germany');
marketMap.set('en', 'English');
marketMap.set('it', 'Italy');
marketMap.set('fr', 'France');
marketMap.set('gb', 'United Kingdom');
marketMap.set('es', 'Spain');
marketMap.set('nl', 'Netherlands');
marketMap.set('bg', 'Bulgarian');
marketMap.set('cs', 'Czech');
marketMap.set('sk', 'Slovak');
marketMap.set('hu', 'Hungarian');
marketMap.set('sr', 'Serbian');
marketMap.set('hr', 'Croatian');
marketMap.set('bs', 'Bosnia');
marketMap.set('da', 'Denmark');
marketMap.set('et', 'Estonia');
marketMap.set('fi', 'Finland');
marketMap.set('el', 'Greece');
marketMap.set('is', 'Iceland');
marketMap.set('lv', 'Latvia');
marketMap.set('lt', 'Lithuania');
marketMap.set('no', 'Norway');
marketMap.set('pt', 'Portugal');
marketMap.set('ro', 'Romania');
marketMap.set('ru', 'Russia');
marketMap.set('sl', 'Slovenia');
marketMap.set('sv', 'Sweden');
marketMap.set('tr', 'Turkey');
marketMap.set('uk', 'Ukraine');

export const countriesMap = new Map();
countriesMap.set('af', 'Afghanistan');
countriesMap.set('al', 'Albania');
countriesMap.set('dz', 'Algeria');
countriesMap.set('as', 'American Samoa');
countriesMap.set('ad', 'Andorra');
countriesMap.set('ao', 'Angola');
countriesMap.set('ai', 'Anguilla');
countriesMap.set('aq', 'Antarctica');
countriesMap.set('ag', 'Antigua and Barbuda');
countriesMap.set('ar', 'Argentina');
countriesMap.set('am', 'Armenia');
countriesMap.set('aw', 'Aruba');
countriesMap.set('au', 'Australia');
countriesMap.set('at', 'Austria');
countriesMap.set('az', 'Azerbaijan');
countriesMap.set('bs', 'Bahamas');
countriesMap.set('bh', 'Bahrain');
countriesMap.set('bd', 'Bangladesh');
countriesMap.set('bb', 'Barbados');
countriesMap.set('by', 'Belarus');
countriesMap.set('be', 'Belgium');
countriesMap.set('bz', 'Belize');
countriesMap.set('bj', 'Benin');
countriesMap.set('bm', 'Bermuda');
countriesMap.set('bt', 'Bhutan');
countriesMap.set('bo', 'Bolivia');
countriesMap.set('ba', 'Bosnia and Herzegovina');
countriesMap.set('bw', 'Botswana');
countriesMap.set('br', 'Brazil');
countriesMap.set('bn', 'Brunei Darussalam');
countriesMap.set('bg', 'Bulgaria');
countriesMap.set('bf', 'Burkina Faso');
countriesMap.set('bi', 'Burundi');
countriesMap.set('kh', 'Cambodia');
countriesMap.set('cm', 'Cameroon');
countriesMap.set('ca', 'Canada');
countriesMap.set('cv', 'Cape Verde');
countriesMap.set('ky', 'Cayman Islands');
countriesMap.set('cf', 'Central African Republic');
countriesMap.set('td', 'Chad');
countriesMap.set('cl', 'Chile');
countriesMap.set('cn', 'China');
countriesMap.set('cx', 'Christmas Island');
countriesMap.set('cc', 'Cocos (Keeling) Islands');
countriesMap.set('co', 'Colombia');
countriesMap.set('km', 'Comoros');
countriesMap.set('cg', 'Congo, Republic of (Brazzaville)');
countriesMap.set('ck', 'Cook Islands');
countriesMap.set('cr', 'Costa Rica');
countriesMap.set('hr', 'Croatia');
countriesMap.set('cu', 'Cuba');
countriesMap.set('cy', 'Cyprus');
countriesMap.set('cz', 'Czech Republic');
countriesMap.set('ci', "Côte D'ivoire (Ivory Coast)");
countriesMap.set('cd', 'Democratic Republic of the Congo (Kinshasa)');
countriesMap.set('dk', 'Denmark');
countriesMap.set('dj', 'Djibouti');
countriesMap.set('dm', 'Dominica');
countriesMap.set('do', 'Dominican Republic');
countriesMap.set('tl', 'East Timor (Timor-Leste)');
countriesMap.set('ec', 'Ecuador');
countriesMap.set('eg', 'Egypt');
countriesMap.set('sv', 'El Salvador');
countriesMap.set('gq', 'Equatorial Guinea');
countriesMap.set('er', 'Eritrea');
countriesMap.set('ee', 'Estonia');
countriesMap.set('et', 'Ethiopia');
countriesMap.set('fk', 'Falkland Islands');
countriesMap.set('fo', 'Faroe Islands');
countriesMap.set('fj', 'Fiji');
countriesMap.set('fi', 'Finland');
countriesMap.set('fr', 'France');
countriesMap.set('gf', 'French Guiana');
countriesMap.set('pf', 'French Polynesia');
countriesMap.set('tf', 'French Southern Territories');
countriesMap.set('ga', 'Gabon');
countriesMap.set('ge', 'Georgia');
countriesMap.set('de', 'Germany');
countriesMap.set('gh', 'Ghana');
countriesMap.set('gi', 'Gibraltar');
countriesMap.set('gr', 'Greece');
countriesMap.set('gl', 'Greenland');
countriesMap.set('gd', 'Grenada');
countriesMap.set('gp', 'Guadeloupe');
countriesMap.set('gu', 'Guam');
countriesMap.set('gt', 'Guatemala');
countriesMap.set('gn', 'Guinea');
countriesMap.set('gw', 'Guinea-Bissau');
countriesMap.set('gy', 'Guyana');
countriesMap.set('ht', 'Haiti');
countriesMap.set('hn', 'Honduras');
countriesMap.set('hk', 'Hong Kong');
countriesMap.set('hu', 'Hungary');
countriesMap.set('is', 'Iceland');
countriesMap.set('in', 'India');
countriesMap.set('id', 'Indonesia');
countriesMap.set('ir', 'Iran (Islamic Republic of)');
countriesMap.set('iq', 'Iraq');
countriesMap.set('ie', 'Ireland');
countriesMap.set('il', 'Israel');
countriesMap.set('it', 'Italy');
countriesMap.set('jm', 'Jamaica');
countriesMap.set('jp', 'Japan');
countriesMap.set('jo', 'Jordan');
countriesMap.set('kz', 'Kazakhstan');
countriesMap.set('ke', 'Kenya');
countriesMap.set('ki', 'Kiribati');
countriesMap.set('kp', "Korea, Democratic People's Rep. (North Korea)");
countriesMap.set('kr', 'Korea, Republic of (South Korea)');
countriesMap.set('xk', 'Kosovo');
countriesMap.set('kw', 'Kuwait');
countriesMap.set('kg', 'Kyrgyzstan');
countriesMap.set('la', "Lao, People's Democratic Republic");
countriesMap.set('lv', 'Latvia');
countriesMap.set('lb', 'Lebanon');
countriesMap.set('ls', 'Lesotho');
countriesMap.set('lr', 'Liberia');
countriesMap.set('ly', 'Libya');
countriesMap.set('li', 'Liechtenstein');
countriesMap.set('lt', 'Lithuania');
countriesMap.set('lu', 'Luxembourg');
countriesMap.set('mo', 'Macau');
countriesMap.set('mg', 'Madagascar');
countriesMap.set('mw', 'Malawi');
countriesMap.set('my', 'Malaysia');
countriesMap.set('mv', 'Maldives');
countriesMap.set('ml', 'Mali');
countriesMap.set('mt', 'Malta');
countriesMap.set('mh', 'Marshall Islands');
countriesMap.set('mq', 'Martinique');
countriesMap.set('mr', 'Mauritania');
countriesMap.set('mu', 'Mauritius');
countriesMap.set('yt', 'Mayotte');
countriesMap.set('mx', 'Mexico');
countriesMap.set('fm', 'Micronesia, Federal States of');
countriesMap.set('md', 'Moldova, Republic of');
countriesMap.set('mc', 'Monaco');
countriesMap.set('mn', 'Mongolia');
countriesMap.set('me', 'Montenegro');
countriesMap.set('ms', 'Montserrat');
countriesMap.set('ma', 'Morocco');
countriesMap.set('mz', 'Mozambique');
countriesMap.set('mm', 'Myanmar, Burma');
countriesMap.set('na', 'Namibia');
countriesMap.set('nr', 'Nauru');
countriesMap.set('np', 'Nepal');
countriesMap.set('nl', 'Netherlands');
countriesMap.set('an', 'Netherlands Antilles');
countriesMap.set('nc', 'New Caledonia');
countriesMap.set('nz', 'New Zealand');
countriesMap.set('ni', 'Nicaragua');
countriesMap.set('ne', 'Niger');
countriesMap.set('ng', 'Nigeria');
countriesMap.set('nu', 'Niue');
countriesMap.set('mk', 'North Macedonia');
countriesMap.set('mp', 'Northern Mariana Islands');
countriesMap.set('no', 'Norway');
countriesMap.set('om', 'Oman');
countriesMap.set('pk', 'Pakistan');
countriesMap.set('pw', 'Palau');
countriesMap.set('ps', 'Palestinian territories');
countriesMap.set('pa', 'Panama');
countriesMap.set('pg', 'Papua New Guinea');
countriesMap.set('py', 'Paraguay');
countriesMap.set('pe', 'Peru');
countriesMap.set('ph', 'Philippines');
countriesMap.set('pn', 'Pitcairn Island');
countriesMap.set('pl', 'Poland');
countriesMap.set('pt', 'Portugal');
countriesMap.set('pr', 'Puerto Rico');
countriesMap.set('qa', 'Qatar');
countriesMap.set('re', 'Reunion Island');
countriesMap.set('ro', 'Romania');
countriesMap.set('ru', 'Russian Federation');
countriesMap.set('rw', 'Rwanda');
countriesMap.set('kn', 'Saint Kitts and Nevis');
countriesMap.set('lc', 'Saint Lucia');
countriesMap.set('vc', 'Saint Vincent and the Grenadines');
countriesMap.set('ws', 'Samoa');
countriesMap.set('sm', 'San Marino');
countriesMap.set('st', 'Sao Tome and Principe');
countriesMap.set('sa', 'Saudi Arabia');
countriesMap.set('sn', 'Senegal');
countriesMap.set('rs', 'Serbia');
countriesMap.set('sc', 'Seychelles');
countriesMap.set('sl', 'Sierra Leone');
countriesMap.set('sg', 'Singapore');
countriesMap.set('sk', 'Slovakia (Slovak Republic)');
countriesMap.set('si', 'Slovenia');
countriesMap.set('sb', 'Solomon Islands');
countriesMap.set('so', 'Somalia');
countriesMap.set('za', 'South Africa');
countriesMap.set('ss', 'South Sudan');
countriesMap.set('es', 'Spain');
countriesMap.set('lk', 'Sri Lanka');
countriesMap.set('sd', 'Sudan');
countriesMap.set('sr', 'Suriname');
countriesMap.set('sz', 'Swaziland (Eswatini)');
countriesMap.set('se', 'Sweden');
countriesMap.set('ch', 'Switzerland');
countriesMap.set('sy', 'Syria, Syrian Arab Republic');
countriesMap.set('tw', 'Taiwan (Republic of China)');
countriesMap.set('tj', 'Tajikistan');
countriesMap.set('tz', 'Tanzania (United Republic of Tanzania)');
countriesMap.set('th', 'Thailand');
countriesMap.set('gm', 'The Gambia');
countriesMap.set('tg', 'Togo');
countriesMap.set('tk', 'Tokelau');
countriesMap.set('to', 'Tonga');
countriesMap.set('tt', 'Trinidad and Tobago');
countriesMap.set('tn', 'Tunisia');
countriesMap.set('tm', 'Turkmenistan');
countriesMap.set('tc', 'Turks and Caicos Islands');
countriesMap.set('tv', 'Tuvalu');
countriesMap.set('tr', 'Türkiye');
countriesMap.set('ug', 'Uganda');
countriesMap.set('ua', 'Ukraine');
countriesMap.set('ae', 'United Arab Emirates');
countriesMap.set('gb', 'United Kingdom');
countriesMap.set('us', 'United States');
countriesMap.set('uy', 'Uruguay');
countriesMap.set('uz', 'Uzbekistan');
countriesMap.set('vu', 'Vanuatu');
countriesMap.set('va', 'Vatican City State (Holy See)');
countriesMap.set('ve', 'Venezuela');
countriesMap.set('vn', 'Vietnam');
countriesMap.set('vg', 'Virgin Islands (British)');
countriesMap.set('vi', 'Virgin Islands (U.S.)');
countriesMap.set('wf', 'Wallis and Futuna Islands');
countriesMap.set('eh', 'Western Sahara');
countriesMap.set('ye', 'Yemen');
countriesMap.set('zm', 'Zambia');
countriesMap.set('zw', 'Zimbabwe');
