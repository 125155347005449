/** @format */

import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { Button } from '../button';
import SelectComponent from '../select';
import { useTranslation } from 'react-i18next';

export default function SurveyModalStep2({ setStep }) {
  const { t } = useTranslation();
  return (
    <Grid className="survey-step survey-step2">
      <Typography className="survey-step-step">{t('homeSurvey.step2Step')}</Typography>
      <Typography className="survey-step-title">{t('homeSurvey.step2Title')}</Typography>
      <Typography className="survey-step-content">{t('homeSurvey.step2Content')}</Typography>
      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <SelectComponent
            label={t('homeSurvey.step2Select1')}
            options={[{ value: 'Gruppe auswählen', name: 'Gruppe auswählen' }]}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <SelectComponent
            label={t('homeSurvey.step2Select2')}
            options={[{ value: 'Rolle auswählen', name: 'Rolle auswählen' }]}
          />
        </Grid>
      </Grid>
      <Button
        onClick={() => setStep(3)}
        label={<label>{t('homeSurvey.step2Btn')}</label>}
        className="survey-step-btn"
      />
    </Grid>
  );
}
