/** @format */

import React from 'react';
import { makeStyles } from '@material-ui/core';
import getRedirectionLink from '../../../utils/helper';
import { Link } from 'react-router-dom';
import useStyles from 'src/components/Loader/styles';

const InfoWidget = props => {
  const data = props.data;
  let image = process.env.REACT_APP_API_URL + data.image;
  return (
    <div className={`info-widget ${useStyles().card}`} data-width={data.w} data-height={data.h}>
      <img src={image} alt="card-icon" className={useStyles().icon} />
      <div className="card-title-outer">
        <h2 className={`card-title ${useStyles().title}`}>{data.title}</h2>
      </div>
      {data?.link ? (
        <a href={getRedirectionLink(data)} target={`${data.linkTarget === '_blank' ? '_blank' : ''}`}>
          <p className="card-text">{data.linkTitle}</p>
        </a>
      ) : (
        <Link to={getRedirectionLink(data)}>
          <p className="card-text">{data.linkTitle}</p>
        </Link>
      )}
    </div>
  );
};

export { InfoWidget };
