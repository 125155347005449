/** @format */

import React, { useState, useEffect, useRef } from 'react';
// import { useSnackbar } from 'notistack';
// import { SiteListDropdown } from './siteListDropdown';
import { Button } from '../button';
import { Link } from 'react-router-dom';
import useAuth from 'src/hooks/useAuth';
// import { Dropdown } from 'antd';
// import { AppsDropdown } from './appsDropdown.jsx';

import { Logo } from '../../configVariable';
// import AppsLogo from 'src/assets/icon/AppsHeaderLogo.svg';
// api lang

// import { useDispatch } from 'src/store';

import { useIdleTimer } from 'react-idle-timer';
import { BrandLogo } from '../icons';
import { Box } from '@material-ui/core';

const Header = props => {
  const { isAuthenticated, onLogin, logout, user } = useAuth();

  const [activeSite, setActiveSite] = useState(null);
  const preLang = usePrevious(props.userLang);

  const timeout = 1000 * 30 * 10;
  const [, setRemaining] = useState(timeout);
  const [, setElapsed] = useState(0);
  const [, setLastActive] = useState(+new Date());
  const [, setIsIdle] = useState(false);

  const handleOnActive = () => setIsIdle(false);
  const handleOnIdle = () => setIsIdle(true);

  const { getRemainingTime, getLastActiveTime, getElapsedTime } = useIdleTimer({
    timeout,
    onActive: handleOnActive,
    onIdle: handleOnIdle
  });

  // const handleChangeActiveSite = async item => {
  //   setActiveSite(item);
  // }; // when click drowdown site

  const handleLogout = async () => {
    await logout();
  };
  const handleLoginAction = async () => {
    const { userLang } = props;
    await onLogin(userLang);
  };

  // const BuildVersion = () => {
  //   return <p align="end">{getBuildVersion()}</p>;
  // };

  function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }

  useEffect(() => {
    const { userLang } = props;
    if (preLang && preLang !== userLang && window.location.pathname === '/dashboard') {
      // window.location.reload()
    }
    // eslint-disable-next-line
  }, [props.userLang]);

  // console.log(props.userLang)

  useEffect(() => {
    setRemaining(getRemainingTime());
    setLastActive(getLastActiveTime());
    setElapsed(getElapsedTime());

    const interval = setInterval(() => {
      setRemaining(getRemainingTime());
      setLastActive(getLastActiveTime());
      setElapsed(getElapsedTime());
    }, 1000);

    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line
  }, []);

  return (
    // <Box sx={{ height: '13.5rem' }}>
    <header>
      <div className="container">
        {/* <div className="site-list apps-button">
            <Dropdown
              overlay={() => <AppsDropdown  />}
              trigger={['click']}
              placement={'bottomRight'}
              overlayClassName="user-info_dropdown">
              <img src={AppsLogo} alt="_apps" onClick={e => e.preventDefault()} />
            </Dropdown>
          </div> */}

        <div className="main-logo">
          <Link to="/" className="link-box">
            {/* <img src={Logo} alt="Logo" /> */}
            <BrandLogo />
          </Link>
        </div>

        <div className="logo-title">Customer Portal</div>

        {/* {isIdle && logout()} */}
        {/* <h1>Idle: {isIdle.toString()}</h1> */}

        {isAuthenticated &&
        activeSite !== null && ( // if logged show logo
            <div className="main-logo">
              {/* <img src={Logo} alt="Logo" /> */}
              <BrandLogo />
            </div>
          )}

        {/* <div className="site-list">
            {activeSite === null || !isAuthenticated ? (
              <SiteListDropdown
                data={siteList}
                activeSite={activeSite}
                handleChangeActiveSite={handleChangeActiveSite}
              /> // add data here
            ) : (
              <span className="active-site">{activeSite.title}</span>
            )}
          </div> */}

        {/* <div style={{ flex: 1, justifyContent: 'flex-end', marginRight: 20 }} className="lang-container">
            <select className="langselect" onChange={props.getSelectLang} value={props.userLang}>
              <option value="en">English - EN</option>
              <option value="fr">France - FR</option>
              <option value="de">Germany - DE</option>
              <option value="es">Spain - ES</option>
              <option value="it">Italy - IT</option>
              <option value="nl">Nederlands - NL</option>
              <option value="de-AT">Austria - AT</option>
              <option value="en-GB">United Kingdom - GB</option>

            </select>
            
          </div> */}
        <div className="user-box">
          {!isAuthenticated && (
            <Button
              label={`login`}
              className="btn-login primary btn-icon__green-triangle"
              onClick={handleLoginAction} // this for test
            />
          )}
        </div>
      </div>
    </header>
    // </Box>
  );
};

export { Header };
