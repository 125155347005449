/** @format */

export const dummyWidget = [
  {
    i: '1',
    isNew: 0,
    w: 3,
    minW: 3,
    maxW: 9,
    h: 5,
    minH: 3,
    maxH: 5,
    type: 'mobility',
    size: 'normal',
    title: 'Smart Mobility Center',
    state: '',
    money: {
      reach: 120000,
      target: 150000
    }
  }, // Smart mobility center
  {
    i: '2',
    isNew: 0,
    w: 6,
    minW: 3,
    maxW: 9,
    h: 5,
    minH: 3,
    maxH: 5,
    type: 'extranet',
    size: 'normal',
    title: 'Aftermarket Extranet',
    data: [
      {
        id: 1,
        title: 'SI 116441 – DTCO 4.0 – Remote download SI 116441 – DTCO 4.0 – Remote download',
        date: '02.04.24',
        category: 'Sales Information'
      },
      {
        id: 2,
        title: 'SI 116441 – DTCO 4.0 – Remote download SI 116441 – DTCO 4.0 – Remote download',
        date: '02.04.24',
        category: 'Sales Information'
      },
      {
        id: 3,
        title: 'SI 116441 – DTCO 4.0 – Remote download SI 116441 – DTCO 4.0 – Remote download',
        date: '02.04.24',
        category: 'Sales Information'
      },
      {
        id: 4,
        title: 'SI 116441 – DTCO 4.0 – Remote download SI 116441 – DTCO 4.0 – Remote download',
        date: '02.04.24',
        category: 'Sales Information'
      },
      {
        id: 5,
        title: 'SI 116441 – DTCO 4.0 – Remote download SI 116441 – DTCO 4.0 – Remote download',
        date: '02.04.24',
        category: 'Sales Information'
      },
      {
        id: 6,
        title: 'SI 116441 – DTCO 4.0 – Remote download SI 116441 – DTCO 4.0 – Remote download',
        date: '02.04.24',
        category: 'Sales Information'
      }
    ]
  }, // Aftermarket Extranet
  {
    i: '3',
    isNew: 0,
    w: 3,
    minW: 3,
    maxW: 9,
    h: 5,
    minH: 3,
    maxH: 5,
    size: 'normal',
    type: 'partnerFinder',
    title: 'VDO Partner',
    terms: ['Brake Center', 'Dealer', 'TPMS Distirbutors', 'General Agent']
  }, // VDO Partner
  {
    i: '4',
    isNew: 0,
    w: 6,
    minW: 3,
    maxW: 9,
    h: 4,
    minH: 4,
    maxH: 6,
    type: 'shop',
    size: 'normal',
    title: 'After Market Webshop -  Bestellungen',
    orders: [
      {
        id: 1,
        number: '468910',
        date: '00.00.0000',
        price: 149.9,
        status: 'in progress'
      },
      {
        id: 3,
        number: '468910',
        date: '00.00.0000',
        price: 149.9,
        status: 'submitted'
      },
      {
        id: 4,
        number: '468910',
        date: '00.00.0000',
        price: 149.9,
        status: 'on hold'
      }
    ]
  }, // Webshop
  {
    i: '5',
    type: 'magazine',
    title: 'Magazin',
    size: 'normal',
    data: [
      {
        id: 1,
        title: 'SINDRI - Vorteile der Fahrzeug-Annahme',
        date: '03.10.24',
        category: 'Event Location',
        text:
          'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis nat Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis nat',
        thumbnail: '/sites/default/files/2021-09/2.png'
      },
      {
        id: 2,
        title: 'SINDRI - Vorteile der Fahrzeug-Annahme',
        date: '03.10.24',
        category: 'Event Location',
        text:
          'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis nat',
        thumbnail: '/sites/default/files/2021-09/323.png'
      },
      {
        id: 3,
        title: 'SINDRI - Vorteile der Fahrzeug-Annahme',
        date: '03.10.24',
        category: 'Event Location',
        text:
          'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis nat',
        thumbnail: '/sites/default/files/2021-09/16-9.png'
      },
      {
        id: 4,
        title: 'SINDRI - Vorteile der Fahrzeug-Annahme',
        date: '03.10.24',
        category: 'Event Location',
        text:
          'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis nat',
        thumbnail: '/static/images/dummy_magazin.jpg'
      }
    ],
    isNew: 0,
    w: 6,
    minW: null,
    maxW: null,
    h: 4,
    minH: null,
    maxH: null
  }, // Magazin
  {
    i: '6',
    type: 'tickets',
    title: 'Support Services 1',
    size: 'normal',
    tickets: [
      {
        title: 'Reparatursatz 13.0441-3805.2 lieferbar?',
        ticketnumber: 'CAS-10810-X3F7F4',
        createdon: '2022-08-12T11:50:12Z',
        status: 'closed',
        description:
          'Herr Gerst benötigte Information zu oben geschilderter Thematik. Kunde interviewt, hierbei kam heraus, das es nicht um diesen Reparatursatz geht, da dieser nicht am Bremssattel seines Fahrzeuges passt, sondern er benötigt einen passenden.',
        incidentid: 'bedabb9d-25a6-4c12-8d6f-0000ea52a17f',
        comments: []
      },
      {
        title: 'Handbremse trotz mehrmaligem nachstellen nicht wirksam?',
        ticketnumber: 'CAS-34091-Y7V4S0',
        createdon: '2024-07-24T06:32:09Z',
        status: 'in progress',
        description:
          'Herr Gardissen benötigte Information zu oben geschilderter Thematik. Kunde hinterließ keine Nachricht auf  Mailbox. Rückruf bei Kunde, Handbremsbacken beim Drum in Head System zeigen trotz mehrmaligem nachstellen keine Bremswirkung. Es wurden auch schon Mitbewerber und OE Bremsbacken verwendet, keine Abhilfe.',
        incidentid: '73e5c46d-8649-ef11-bfe2-000d3a64ad5f',
        comments: []
      },
      {
        title: 'MOD  Test123',
        ticketnumber: 'CAS-34343-J5F7Q9',
        createdon: '2024-07-31T05:04:35Z',
        status: 'pending',
        description: 'Test Ticket',
        incidentid: '8243e45d-fa4e-ef11-bfe2-000d3a64ad5f',
        comments: []
      },
      {
        title: 'Ermittlung Teilenummern Bremsen hinten Renault Scenic',
        ticketnumber: 'CAS-34732-Q0Y8D8',
        createdon: '2024-08-08T13:24:47Z',
        status: 'new',
        description:
          'Kunde fragt bzgl. Sachnummern für Scheiben und Beläge Bemsen hinten.\nRenault Scenic II 1.6 16V\nTyp: JM1R\nBaujahr 2009\nMotor: K4M 766\nVIN: VF1JM1R0641166149\nProduktionsdatum: 2009-04-06',
        incidentid: 'a60b0691-8955-ef11-bfe3-000d3a64ad5f',
        comments: []
      },
      {
        title: 'Reparatursatz 13.0441-3805.2 lieferbar?',
        ticketnumber: 'CAS-10810-X3F7F4',
        createdon: '2022-08-12T11:50:12Z',
        status: 'closed',
        description:
          'Herr Gerst benötigte Information zu oben geschilderter Thematik. Kunde interviewt, hierbei kam heraus, das es nicht um diesen Reparatursatz geht, da dieser nicht am Bremssattel seines Fahrzeuges passt, sondern er benötigt einen passenden.',
        incidentid: 'bedabb9d-25a6-4c12-8d6f-0000ea52a17f',
        comments: []
      },
      {
        title: 'Handbremse trotz mehrmaligem nachstellen nicht wirksam?',
        ticketnumber: 'CAS-34091-Y7V4S0',
        createdon: '2024-07-24T06:32:09Z',
        status: 'in progress',
        description:
          'Herr Gardissen benötigte Information zu oben geschilderter Thematik. Kunde hinterließ keine Nachricht auf  Mailbox. Rückruf bei Kunde, Handbremsbacken beim Drum in Head System zeigen trotz mehrmaligem nachstellen keine Bremswirkung. Es wurden auch schon Mitbewerber und OE Bremsbacken verwendet, keine Abhilfe.',
        incidentid: '73e5c46d-8649-ef11-bfe2-000d3a64ad5f',
        comments: []
      }
    ],
    isNew: 0,
    w: 4,
    minW: 3,
    maxW: 9,
    h: 4,
    minH: 4,
    maxH: 6
  }, // Support
  {
    i: '17',
    type: 'academy',
    title: 'VDOAcademy Trainings',
    size: 'normal',
    trainings: [
      {
        id: 1,
        title: 'WorkshopTab Konfigurator-Tool: Online-Kur',
        image: '',
        date: '00.00.0000',
        location: 'Location',
        text:
          'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis nat, Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis nat'
      },
      {
        id: 2,
        title: 'WorkshopTab Konfigurator-Tool: Online-Kur',
        image: '',
        date: '00.00.0000',
        location: 'Location',
        text:
          'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis nat, Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis nat'
      },
      {
        id: 3,
        title: 'WorkshopTab Konfigurator-Tool: Online-Kur',
        image: '',
        date: '00.00.0000',
        location: 'Location',
        text:
          'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis nat, Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis nat'
      },
      {
        id: 4,
        title: 'WorkshopTab Konfigurator-Tool: Online-Kur',
        image: '',
        date: '00.00.0000',
        location: 'Location',
        text:
          'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis nat, Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis nat'
      }
    ],
    bookings: [],
    isNew: 0,
    w: 4,
    minW: 3,
    maxW: 9,
    h: 4,
    minH: 4,
    maxH: 6
  }, // Academy
  {
    i: '7',
    title: 'News & Presse',
    size: 'normal',
    type: 'news',
    isNew: 0,
    w: 4,
    minW: null,
    maxW: null,
    h: 4,
    minH: null,
    maxH: null,
    news: [
      {
        nid: 1,
        title: 'WorkshopTab Konfigurator-Tool: Online-Kur',
        content:
          'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis nat. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis nat. Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis nat. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis nat.',
        date: '03.10.24',
        category: 'Event Location'
      },
      {
        nid: 2,
        title: 'WorkshopTab Konfigurator-Tool: Online-Kur',
        content:
          'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis nat. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis nat. Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis nat. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis nat.',
        date: '03.10.24',
        category: 'Event Location'
      },
      {
        nid: 3,
        title: 'WorkshopTab Konfigurator-Tool: Online-Kur',
        content:
          'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis nat. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis nat.',
        date: '03.10.24',
        category: 'Event Location'
      },
      {
        nid: 4,
        title: 'WorkshopTab Konfigurator-Tool: Online-Kur',
        content:
          'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis nat. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis nat.',
        date: '03.10.24',
        category: 'Event Location'
      },
      {
        nid: 5,
        title: 'WorkshopTab Konfigurator-Tool: Online-Kur',
        content:
          'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis nat. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis nat.',
        date: '03.10.24',
        category: 'Event Location'
      },
      {
        nid: 6,
        title: 'WorkshopTab Konfigurator-Tool: Online-Kur',
        content:
          'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis nat. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis nat.',
        date: '03.10.24',
        category: 'Event Location'
      }
    ]
  }, // News
  {
    i: '8',
    title: 'KaaS Sandbox',
    content:
      'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis nat. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis nat. Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis nat. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis nat.',
    category: 'KaaS',
    highlight: true,
    image: '/sites/default/files/2021-09/2.png',
    size: 'normal',
    type: 'link',
    linkTarget: '_blank',
    link: 'https://sandbox.eval.itsconnectedcar.com',
    linkTitle: 'Go to Sandbox',
    isNew: 0,
    w: 3,
    minW: 3,
    maxW: 4,
    h: 4,
    minH: 3,
    maxH: 5
  }, // Link
  {
    i: '9',
    title: 'KaaS Production',
    content:
      'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis nat. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis nat. Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis nat. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis nat.',
    category: 'KaaS',
    highlight: true,
    image: '/sites/default/files/2021-09/323.png',
    size: 'normal',
    type: 'link',
    linkTarget: '_blank',
    link: 'https://sandbox.eval.itsconnectedcar.com',
    linkTitle: 'Go to Production',
    isNew: 0,
    w: 3,
    minW: 3,
    maxW: 4,
    h: 4,
    minH: 3,
    maxH: 5
  }, // Link
  {
    i: '10',
    title: 'Download OBD comp list',
    content: 'Download the OBD comp list',
    category: 'KaaS',
    highlight: true,
    image: '/sites/default/files/2021-09/16-9.png',
    size: 'normal',
    type: 'link',
    linkTarget: '_blank',
    link: 'https://sandbox.eval.itsconnectedcar.com',
    linkTitle: 'Click here',
    isNew: 0,
    w: 3,
    minW: 3,
    maxW: 4,
    h: 4,
    minH: 3,
    maxH: 5
  }, // Link
  {
    i: '11',
    title: 'KaaS Sandbox',
    content:
      'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis nat. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis nat. Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis nat. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis nat.',
    category: 'KaaS',
    highlight: true,
    image: '/sites/default/files/2021-09/2.png',
    size: 'normal',
    type: 'link',
    linkTarget: '_blank',
    link: 'https://sandbox.eval.itsconnectedcar.com',
    linkTitle: 'Go to Sandbox',
    isNew: 0,
    w: 3,
    minW: 3,
    maxW: 4,
    h: 4,
    minH: 3,
    maxH: 5
  }, // Link
  {
    i: '12',
    title: 'Nächste Events & Messen 1',
    size: 'normal',
    type: 'events',
    isNew: 0,
    w: 4,
    minW: 3,
    maxW: 9,
    h: 6,
    minH: 3,
    maxH: 6,
    data: [
      {
        id: 1,
        title: 'WorkshopTab Konfigurator-Tool: Online-Kur',
        text:
          'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis nat. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis nat.',
        date: '03.10.24',
        category: 'Event Location'
      },
      {
        id: 2,
        title: 'WorkshopTab Konfigurator-Tool: Online-Kur',
        text:
          'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis nat. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis nat.',
        date: '03.10.24',
        category: 'Event Location'
      },
      {
        id: 3,
        title: 'WorkshopTab Konfigurator-Tool: Online-Kur',
        text:
          'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis nat. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis nat.',
        date: '03.10.24',
        category: 'Event Location'
      },
      {
        id: 4,
        title: 'WorkshopTab Konfigurator-Tool: Online-Kur',
        text:
          'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis nat. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis nat.',
        date: '03.10.24',
        category: 'Event Location'
      },
      {
        id: 5,
        title: 'WorkshopTab Konfigurator-Tool: Online-Kur',
        text:
          'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis nat. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis nat.',
        date: '03.10.24',
        category: 'Event Location'
      },
      {
        id: 6,
        title: 'WorkshopTab Konfigurator-Tool: Online-Kur',
        text:
          'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis nat. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis nat.',
        date: '03.10.24',
        category: 'Event Location'
      },
      {
        id: 7,
        title: 'WorkshopTab Konfigurator-Tool: Online-Kur',
        text:
          'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis nat. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis nat.',
        date: '03.10.24',
        category: 'Event Location'
      },
      {
        id: 8,
        title: 'WorkshopTab Konfigurator-Tool: Online-Kur',
        text:
          'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis nat. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis nat.',
        date: '03.10.24',
        category: 'Event Location'
      }
    ]
  }, // Events
  {
    i: '13',
    title: 'Multi-videos test',
    size: 'normal',
    type: 'video',
    isNew: 0,
    w: 8,
    minW: 3,
    maxW: 9,
    h: 6,
    minH: 3,
    maxH: 6,
    videos: [
      {
        id: 1,
        content: 'SINDRI - Vorteile der Fahrzeug-Annahme',
        link: 'https://youtu.be/Cbf5ANQEHWE',
        time: '10:11',
        date: '03.10.24',
        category: 'Event Location'
      },
      {
        id: 2,
        content: 'SINDRI - Vorteile der Fahrzeug-Annahme',
        link: 'https://youtu.be/Cbf5ANQEHWE',
        time: '10:11',
        date: '03.10.24',
        category: 'Event Location'
      },
      {
        id: 3,
        content: 'SINDRI - Vorteile der Fahrzeug-Annahme',
        link: 'https://youtu.be/Cbf5ANQEHWE',
        time: '10:11',
        date: '03.10.24',
        category: 'Event Location'
      }
    ]
  }, // Videos
  {
    i: '14',
    isNew: 0,
    w: 4,
    minW: 3,
    maxW: 4,
    h: 4,
    minH: 2,
    maxH: 4,
    type: 'info',
    size: 'normal',
    image: '/sites/default/files/2021-09/card.svg',
    title: 'DLK Pro 3.1 Update',
    link: 'https://youtu.be/Cbf5ANQEHWE',
    linkTitle: 'Hier gehts zum Update',
    linkTarget: '_blank'
  }, // info
  {
    i: '15',
    isNew: 0,
    w: 4,
    minW: 3,
    maxW: 4,
    h: 4,
    minH: 2,
    maxH: 4,
    type: 'info',
    size: 'normal',
    image: '/sites/default/files/2021-09/cart.svg',
    title: 'VDO Produkte und Umrüstungstermine',
    link: 'https://staging.my.vdo.com/page/117',
    linkTitle: 'Zur Händler Übersicht',
    linkTarget: '_blank'
  }, // info
  {
    i: '16',
    isNew: 0,
    w: 4,
    minW: 3,
    maxW: 4,
    h: 2,
    minH: 2,
    maxH: 4,
    type: 'contact',
    size: 'normal',
    image: '/sites/default/files/2021-09/Message.png',
    title: 'KaaS Contact',
    nid: 27,
    link: 'ContactForm',
    links: null,
    linkTitle: 'Contact Form',
    linkTarget: null,
    tag: null,
    jsSource: null,
    cssSource: null
  }, // contact
  {
    i: '18',
    isNew: 0,
    w: 4,
    minW: 3,
    maxW: 4,
    h: 2,
    minH: 2,
    maxH: 4,
    type: 'contact',
    size: 'normal',
    image: '/sites/default/files/2021-09/Message.png',
    title: 'KaaS Contact',
    nid: 27,
    link: 'ContactForm',
    links: null,
    linkTitle: 'Contact Form',
    linkTarget: null,
    tag: null,
    jsSource: null,
    cssSource: null
  } // contact
];
